import { connect } from 'react-redux';
import App from './App';

const mapStateToProps = state => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const locale = state.get('locale').get('lang');
  const router = state.get('router');
  return {
    ...auth,
    locale,
    router
  };
};

const mapDispatchToProps = {
  // ...loginActions
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
