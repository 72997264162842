import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import styles from './App.styles';
import { IntlProvider } from 'react-intl';
import axios from 'axios';
import translations from './i18n/locales';
import KioskLayout from './components/Kiosk/KioskLayout';
import KioskPickupLayout from './components/KioskPickup/KioskPickupLayout';
import KioskVerifyMeLayout from './components/KioskVerifyMe/KioskVerifyMeLayout';
import KioskConfimPickupLayout from './components/KioskConfimPickup/KioskConfimPickupLayout';
import KioskSignatureMain from './components/KioskSignature/KioskSignatureMain';
import KioskConsultLayout from './components/KioskConsult/KioskConsultLayout';
import KioskFeedbackLayout from './components/KioskFeedback/KioskFeedbackLayout';
import KioskDoorLayout from './components/KioskDoor/KioskDoorLayout';
import KioskCollectIDLayout from './components/KioskCollenctID/KioskCollectIDLayout';
import KioskEnrollmentLayout from './components/KioskEnrollment/KioskEnrollmentLayout';
import AdminLoginLayout from './components/AdminLogin/AdminLoginLayout';
import SecureBadgeLoginForm from './components/SecureBadgeLogin/SecureBadgeLoginForm';
import OktaLogin from './components/OktaLogin/OktaLoginForm';
import PatientLoginLayout from './components/PatientLogin/PatientLoginLayout';
import AdminPanelLayout from './components/AdminPanel/AdminPanelLayout';
import AdminPanelStockLayout from './components/AdminPanelStock/AdminPanelStockLayout';
import AdminKioskVerifyLayout from './components/KioskVerify/AdminKioskVerifyLayout';
import AdminMaintenanceLayout from './components/AdminMaintenance/AdminMaintenanceLayout';
import AdminSettingLayout from './components/AdminSetting/AdminSettingLayout';
import LoginLayout from './components/Login/LoginLayout';
import KioskOptHrsMain from './components/KioskOptHrs/KioskOptHrsMain';
import { initialSettings } from './reducers/data';
import KioskWelcomeScreenLayout from './components/KioskWelcomeScreen/KioskWelcomeScreenLayout';
import SimpleStartKioskWelcomeScreenLayout from './components/KioskSimpleStartWelcomeScreen/SimpleStartKioskWelcomeScreenLayout';

import KioskCheckinLayout from './components/KioskCheckin/KioskCheckinLayout';
import KioskNewPatientEnrollmentLayout from './components/KioskNewPateint/KioskNewPatientEnrollmentLayout';
import KioskNewPatientSearchResultLayout from './components/KioskNewPatientSearchResult/KioskNewPatientSearchResultLayout';
import KioskCurrentPatientSearchLayout from './components/KioskCurrentPatient/KioskCurrentPatientSearchLayout';
import KioskCurrentPatientSearchResultLayout from './components/KioskCurrentPatientSearchResult/KioskCurrentPatientSearchResultLayout';
import KioskPatientLoginLayout from './components/KioskPatientLogin/KioskPatientLoginLayout';
import KioskPatientViewRxLayout from './components/KioskPatientViewRx/KioskPatientViewRxLayout';
import KioskOrderItemLayout from './components/KioskOrderItem/KioskOrderItemLayout';
import KioskHealthandinformationLayout from './components/KioskHealthandinformation/KioskHealthandinformationLayout';
import KioskRefillRxLayout from './components/KioskRefillRx/KioskRefillRxLayout';
import KioskShopNowLayout from './components/KioskShopNow/KioskShopNowLayout';
import KioskProductListLayout from './components/KioskProductList/KioskProductListLayout';
import KioskGetKitLayout from './components/KioskGetKit/KioskGetKitLayout';
import KioskQuizPassLayout from './components/KioskQuizPass/KioskQuizPassLayout';
import KioskQuizTestLayout from './components/KioskQuizTest//KioskQuizTestLayout';
import KioskQuizEnrollmentLayout from './components/KioskQuizEnrollment/KioskQuizEnrollmentLayout';
import KioskCheckConfirmuserLayout from './components/KioskCheckConfirmuser/KioskCheckConfirmuserLayout';
import KioskInformationMain from './components/KioskInformation/KioskInformationMain';
import ContactlessPickupLayout from './components/ContactlessCapturePickup/ContactlessPickupLayout';
import {
  startPolling,
  stopPolling,
  updatePollingStats,
  updateData,
  initialData
} from './api/common/actions';
import AdminRemoveLayout from './components/AdminRemove/AdminRemoveLayout';

//In wall Rear Stock

import RearStockAdminLoginLayout from './components/InWallRearStock/AdminLogin/AdminLoginLayout';
import RearStockAdminPanelLayout from './components/InWallRearStock/AdminPanel/AdminPanelLayout';
import RearStockAdminPanelStockLayout from './components/InWallRearStock/AdminPanelStock/AdminPanelStockLayout';
import RearStockAdminMaintenanceLayout from './components/InWallRearStock/AdminMaintenance/AdminMaintenanceLayout';
import RearStockAdminSettingLayout from './components/InWallRearStock/AdminSetting/AdminSettingLayout';
import RearStockAdminRemoveLayout from './components/InWallRearStock/AdminRemove/AdminRemoveLayout';
import RearStockAdminKioskVerifyLayout from './components/InWallRearStock/KioskVerify/AdminKioskVerifyLayout';
import {
  writeInitSettings,
  readSettings,
  openBin,
  WriteInitPickupInfo
} from './helpers/board';
import ErrorBoundarys from './components/ErrorBoundry/ErrorBoundary';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { ConfigureAmplify } from './helpers/amplify';
const Cryptr = require('cryptr');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);
const client = new W3CWebSocket('wss://dev.socket.localbox.net/');
const {
  writePickupLogsToFile,
  sendAlarmMessageForOutOfSession
} = require('./components/Common/SyncAPIs');

let FileObje = {
  boxId: '',
  dynamicDataSource: false,
  rearStock: false,
  IsActive: true,
  pickupType: '1',
  steps: [
    {
      step: 0,
      name: 'Touch to Start',
      value: true,
      prev: '',
      next: '',
      btnClick: 0,
      stepTitle: 0
    },
    {
      step: 1,
      name: 'Pick up Code Enter',
      value: true,
      prev: '/',
      next: '/KioskVerify',
      btnClick: 0,
      stepTitle: 1
    },
    {
      step: 2,
      name: 'DOB verification',
      value: true,
      prev: '/KioskPickupCode',
      next: '/KioskConfirmPickup',
      btnClick: 0,
      stepTitle: 2
    },
    {
      step: 3,
      name: 'Confirm Pick up items',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 3
    },
    {
      step: 4,
      name: 'Consult',
      value: true,
      prev: '/KioskConfirmPickup',
      next: '/KioskSignature',
      btnClick: 0,
      stepTitle: 4
    },
    {
      step: 5,
      name: 'Signature',
      value: true,
      prev: '/KioskConsult',
      next: '/KioskCheckConfirmuser', //Modified By Belani Jaimin
      btnClick: 0,
      stepTitle: 5
    },
    {
      step: 6,
      name: 'DL Capture',
      value: true,
      prev: '/KioskSignature',
      next: '/KioskCollectMedicines',
      btnClick: 0,
      stepTitle: 6
    },
    {
      step: 7,
      name: 'Collect your items',
      value: true,
      prev: '',
      next: '/KioskFeedback',
      btnClick: 0,
      stepTitle: 7
    },
    {
      step: 8,
      name: 'Survey',
      value: true,
      prev: '',
      next: '/',
      btnClick: 0,
      stepTitle: 8
    },
    {
      step: 9,
      name: 'Confirm Pickup Payment',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 9
    },
    {
      step: 10,
      name: 'View Current Rx',
      value: true,
      prev: '/KioskPatientViewRx',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 0
    }
  ],
  posWaitDuration: 3000,
  IsNDCVideoAllow: false,
  /*
    Added By : Belani Jaimin
    Description: Send payment request to ingenico device
    Note: The default URL is base url of kiosk-wrapper code configuration.
  */
  paymentBaseUrl: '',
  isHWCommuniocationEnabled: true,
  EnableLifeboat: false
};

let initialPickupObject = {
  message: 'Initialize a file for logs',
  timestamp: new Date()
};

class App extends React.Component {
  componentWillMount() {
    if (window.electron) {
      writeInitSettings(FileObje);
      setTimeout(() => {
        WriteInitPickupInfo(initialPickupObject);
      });
    }
    if (localStorage.getItem('steps') === null) {
      localStorage.setItem('steps', JSON.stringify(FileObje.steps));
    }
    if (localStorage.getItem('paymentBaseUrl') === null) {
      localStorage.setItem('paymentBaseUrl', FileObje.paymentBaseUrl);
    }
    this.startPolling();
    this.kioskConnectWithWebsocket();
    this.checkKioskHealth();
    if (window.electron) {
      window.getBoardData = args => {
        if (args && args.data && args.data.length > 0) {
          const _checkIfSessionIsActiveOrNot = localStorage.getItem(
            'ActiveSession'
          );
          const decryptedSessionObject = _cryptr.decrypt(
            _checkIfSessionIsActiveOrNot
          );
          const parseddecryptedSessionObject = JSON.parse(
            decryptedSessionObject
          );
          if (
            (parseddecryptedSessionObject &&
              parseddecryptedSessionObject._session_active === false) ||
            (parseddecryptedSessionObject &&
              parseddecryptedSessionObject._session_active === 'false')
          ) {
            sendAlarmMessageForOutOfSession();
          }
        }
      };
    }
  }

  componentDidMount() {
    ConfigureAmplify();
  }

  timeoutPromise = (ms, promise) => {
    try {
      return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          const isKioskComesOnline = localStorage.getItem('isKioskOnline');
          console.log(`isKioskComesOnline : ${isKioskComesOnline}`);
          if (isKioskComesOnline === 'true') {
            window.dispatchEvent(new Event('offline'));
          }
          return;
        }, ms);
        promise.then(
          res => {
            clearTimeout(timeoutId);
            resolve(res);
          },
          err => {
            clearTimeout(timeoutId);
            reject(err);
          }
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  checkKioskHealth = () => {
    setInterval(() => {
      const retVal = this.timeoutPromise(
        process.env.REACT_APP_KIOSK_HEALTH_TIMEOUT,
        fetch(process.env.REACT_APP_BASEURL + 'health/healthz', {
          method: 'get'
        })
          .then(() => {
            const isKioskComesOnline = localStorage.getItem('isKioskOnline');
            if (isKioskComesOnline === null || isKioskComesOnline === 'false') {
              window.dispatchEvent(new Event('online'));
            }
            const isQueueAvailable = localStorage.getItem('queue');
            if (!isQueueAvailable) {
              localStorage.setItem('queue', []);
            } else {
              this.loopTheQueueAndSync();
            }
          })
          .catch(error => {
            console.log(`Error with checking health of kiosk ${error}`);
            const isKioskComesOnline = localStorage.getItem('isKioskOnline');
            if (isKioskComesOnline === 'true') {
              window.dispatchEvent(new Event('offline'));
              const isQueueAvailable = localStorage.getItem('queue');
              if (!isQueueAvailable) {
                localStorage.setItem('queue', []);
              }
            }
          })
      );
    }, process.env.REACT_APP_KIOSK_HEALTH_INTERVAL);
  };

  loopTheQueueAndSync = async () => {
    let sourceEnabled;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      sourceEnabled = fileData ? fileData.dynamicDataSource : false;
    } else {
      sourceEnabled = JSON.parse(localStorage.getItem('dynamicDataSource'));
    }
    let queue = localStorage.getItem('queue');
    if (queue) {
      let parsedQueue = JSON.parse(queue);
      for (let i = 0; i < parsedQueue.length; i++) {
        if (sourceEnabled === true) {
          if (parsedQueue[i].payload.name === 'saveKioskCustomer') {
            await this.saveConfirmUser(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'updateStatusOfRx') {
            await this.UpdateStatusOfRx(parsedQueue[i].payload, i);
          } else if (parsedQueue[i].payload.name === 'saveCustomerRatting') {
            await this.SavePatientRating(parsedQueue[i].payload, i);
          }
        } else {
          if (parsedQueue[i].payload.name === 'sendEmail') {
            await this.SendAnEmailWhenDataPollingIsOff(
              parsedQueue[i].payload,
              i
            );
          }
        }
      }
    }
  };

  getBoxesData = () => {
    let boxId, dataSource;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
      dataSource = fileData ? fileData.dynamicDataSource : false;
      FileObje.steps =
        fileData && fileData.steps ? fileData.steps : FileObje.steps;
    } else {
      boxId = localStorage.getItem('boxId');
      dataSource = JSON.parse(localStorage.getItem('dynamicDataSource'));
      FileObje.steps =
        localStorage.getItem('steps') !== null &&
        localStorage.getItem('steps') !== undefined
          ? JSON.parse(localStorage.getItem('steps'))
          : FileObje.steps;
    }

    if (dataSource === false || dataSource === null) {
      this.props.initialData(initialSettings);
      return true;
    } else {
      const token = localStorage.getItem('authTokens');
      const actualToken = atob(token);
      if (boxId) {
        return axios
          .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`, {
            headers: {
              Authorization: 'Bearer ' + actualToken,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.props.updateData(response.data);
            const coolExpansion = response.data.box.box_expansions.find(
              x => x.boxes_type.name === 'Cool (Temp controlled with 8 bins)'
            );
            if (coolExpansion) {
              console.log(
                `coolExpansion.target_temp: ${coolExpansion.target_temp}`
              );
              console.log(`window.targetTempValue: ${window.targetTempValue}`);
              if (coolExpansion.target_temp !== window.targetTempValue) {
                // TODO: Write target temp
                if (window.writeCoolTemp) {
                  window.writeCoolTemp(coolExpansion.target_temp);
                  window.targetTempValue = coolExpansion.target_temp;
                } else {
                  console.error(
                    `window.writeCoolTemp: ${window.writeCoolTemp}`
                  );
                }
              }
              if (!window.updateTemp) {
                window.updateTemp = tempEvent => {
                  console.log(coolExpansion.id);
                  console.log(tempEvent);
                  axios
                    .post(
                      `${process.env.REACT_APP_BASEURL}temperature/history`,
                      {
                        data: [
                          {
                            expansion_id: coolExpansion.id,
                            temperature: tempEvent.data,
                            timestamp: tempEvent.datetime,
                            port: tempEvent.port,
                            event: tempEvent.event
                          }
                        ]
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + actualToken,
                          'Content-Type': 'application/json'
                        }
                      }
                    )
                    .then(response => {});
                };
              }
            }
            return response.data;
          });
      } else {
        return axios
          .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${null}`, {
            headers: {
              Authorization: 'Bearer ' + actualToken,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.props.updateData(response.data);
            return response.data;
          });
      }
    }
  };
  kioskConnectWithWebsocket() {
    let boxId = '';
    if (window.electron) {
      setTimeout(() => {
        const fileData = JSON.parse(readSettings());
        boxId = fileData ? fileData.boxId : '';
      }, 500);
    } else {
      boxId =
        localStorage.getItem('boxId') !== null
          ? localStorage.getItem('boxId')
          : '';
    }
    client.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    client.onmessage = message => {
      let dataFromServer = JSON.parse(message.data);
      let boxdetail =
        dataFromServer &&
        dataFromServer.data &&
        dataFromServer.data.users &&
        dataFromServer.data.users[boxId.toUpperCase()];
      if (boxdetail !== undefined && boxdetail !== 'undefined') {
        const stateToChange = {};
        if (
          dataFromServer.type === 'PORTAL' &&
          boxdetail.command === 'OPEN-BIN'
        ) {
          // stateToChange.currentUsers = Object.values(dataFromServer.data.users);
          this.openBinFromConnect(boxdetail.bin, boxId.toUpperCase());
        } else if (dataFromServer.type === 'KIOSK') {
          // stateToChange.text = dataFromServer.data.editorContent || contentDefaultMessage;
        }
        // stateToChange.userActivity = dataFromServer.data.userActivity;
        this.setState({
          ...stateToChange
        });
      }
    };
    client.onerror = () => {
      console.log(`Client error`);
    };
    client.onclose = () => {
      console.log(`connection closed`);
    };
  }
  openBinFromConnect(bin, boxId) {
    openBin(bin);
    let command = 'OPEN-BIN-SUCCESS';
    let box_id = boxId;
    if (box_id.trim()) {
      const data = {
        command,
        bin,
        box_id
      };
      this.setState(
        {
          ...data
        },
        () => {
          client.send(
            JSON.stringify({
              ...data,
              type: 'KIOSK'
            })
          );
        }
      );
    }
  }
  getUpdatedBoxes = response => {
    if (response && response.data) {
      this.props.updateData(response.data);
      return true;
    }
    return false;
  };

  handleStatsChange = stats => {
    this.props.updatePollingStats(stats);
  };

  startPolling = () => {
    this.props.startPolling({
      asyncFetch: this.getBoxesData,
      callback: this.getUpdatedBoxes,
      onStatsChange: this.handleStatsChange,
      delay: 10, // 10 Seconds delay between successive polls
      retryOnFailure: true, // Retry if API call fails
      retryAfter: 5 // Retry after 5 seconds
      // stopAfterRetries: 50               // Give up after 50 failure retries
    });
  };

  // Save Captured details of DL for pickup
  saveConfirmUser = async (payload, index) => {
    // console.log(`Index : ${index} and payload : ${JSON.stringify(payload)}`);
    // let log = {
    //   message: `Kiosk Queue : Save kiosk customer collected details API called from queue with payload : ${JSON.stringify(
    //     payload
    //   )}`
    // };
    // writePickupLogsToFile(log);
    let pickupType = '1';
    pickupType =
      localStorage.getItem('pickupType') !== null
        ? localStorage.getItem('pickupType')
        : '1';
    let patientInfo = JSON.parse(localStorage.getItem('patient'));
    let order_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.OrderID;
    let orderIds = [];
    if (pickupType === '1') {
      orderIds.push({
        id:
          payload &&
          payload.body &&
          payload.body.formData &&
          payload.body.formData.OrderID
      });
    } else if (pickupType === '2') {
      patientInfo[0].orders.map(order => {
        orderIds.push({ id: order.id });
        return true;
      });
    }
    let customer_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.CustomerID;
    let account_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.AccountID;
    let box_id =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.BoxID;
    let base64Picture = null;
    let base64Signature = null;
    if (
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.picture_url !== null &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.picture_url !== '' &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.picture_url !== undefined
    ) {
      base64Picture =
        payload &&
        payload.body &&
        payload.body.formData &&
        payload.body.formData.picture_url.replace(
          /^data:image\/\w+;base64,/,
          ''
        );
    }
    if (
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.signature_url !== null &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.signature_url !== '' &&
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.signature_url !== undefined
    ) {
      base64Signature =
        payload &&
        payload.body &&
        payload.body.formData &&
        payload.body.formData.signature_url.replace(
          /^data:image\/\w+;base64,/,
          ''
        );
    }

    let isConsultRequested =
      payload &&
      payload.body &&
      payload.body.formData &&
      payload.body.formData.isConsultRequested;
    if (
      isConsultRequested === undefined ||
      isConsultRequested === 'undefined' ||
      isConsultRequested === null ||
      isConsultRequested === 'null' ||
      isConsultRequested === ''
    ) {
      isConsultRequested = false;
    } else {
      isConsultRequested =
        payload &&
        payload.body &&
        payload.body.formData &&
        payload.body.formData.isConsultRequested;
    }

    let data = new FormData();
    data.append('customer_id', customer_id);
    data.append('order_id', order_id);
    data.append('picture_url', base64Picture);
    data.append('signature_url', base64Signature);
    data.append('drivers_license_no', payload.body.formData.DriversLicenseNo);
    data.append('first_name', payload.body.formData.FirstName);
    data.append('middle_name', null);
    data.append('last_name', payload.body.formData.LastName);
    data.append('dob', payload.body.formData.dateOfBirth);
    data.append('relationship', payload.body.formData.value);
    data.append('box_id', box_id);
    data.append('account_id', account_id);
    data.append('orderIds', JSON.stringify(orderIds));
    data.append('selectedStateCode', payload.body.formData.selectedStateCode);
    data.append('dlrawdata', payload.body.formData.dlrawdata);
    data.append(
      'kioskBoxAccountConnection',
      payload.body.formData.kioskBoxAccountConnection
    );
    data.append('is_consult_requested', isConsultRequested);
    try {
      fetch(payload.url, {
        method: payload.type,
        body: data
      })
        .then(response => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then(uData => {
          let log = {
            message: `Kiosk Queue : Getting response from Save kiosk customer collected details called from queue: ${JSON.stringify(
              uData
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from Save kiosk customer collected details from queue: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  //Update Status of Rx in cloud after pickup with email send functionality
  UpdateStatusOfRx = async (payload, index) => {
    let log = {
      message: `Kiosk Queue : Update Status of Rx API called from queue with payload : ${JSON.stringify(
        payload
      )}`
    };
    writePickupLogsToFile(log);
    try {
      fetch(payload.url, {
        method: payload.type,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload.body.updateStatusOfRxObj)
      })
        .then(response => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then(RxObj => {
          let log = {
            message: `Kiosk Queue : Getting response from update status of Rx : ${JSON.stringify(
              RxObj
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from update status of Rx from queue: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  // Save rating given by the patient
  SavePatientRating = async (rattingObj, index) => {
    let log = {
      message: `Kiosk Queue : feedback API called from queue with payload: ${JSON.stringify(
        rattingObj
      )}`
    };
    writePickupLogsToFile(log);
    try {
      fetch(process.env.REACT_APP_BASEURL + 'saveCustomerRatting', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(rattingObj)
      })
        .then(response => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then(uData => {
          let log = {
            message: `Kiosk Queue : Getting feedback API response from queue : ${JSON.stringify(
              uData
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from feedback API from queue: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  SendAnEmailWhenDataPollingIsOff = async (payload, index) => {
    let pickupConfig = payload.body.pickupConfig;
    let tempConfigObj = { pickupConfig };
    let log = {
      message: `Kiosk Queue : Send an Email when data polling is off API called from queue with payload: ${JSON.stringify(
        payload
      )}`
    };
    writePickupLogsToFile(log);
    try {
      fetch(payload.url, {
        method: payload.type,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(tempConfigObj)
      })
        .then(response => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then(res => {
          let log = {
            message: `Kiosk Queue : Getting response from Send an Email when data polling is off API from queue with payload: ${JSON.stringify(
              res
            )}`
          };
          writePickupLogsToFile(log);
          this.dequeueItem(index);
        });
    } catch (Exception) {
      let log = {
        message: `Kiosk Queue : Getting error from Send an Email when data polling is off API from queue with payload: ${JSON.stringify(
          Exception
        )}`
      };
      writePickupLogsToFile(log);
    }
  };

  stopPolling = () => {
    this.props.updatePollingStats({});
    this.props.stopPolling();
  };

  dequeueItem = index => {
    let queue = localStorage.getItem('queue');
    if (queue) {
      let parsedQueue = JSON.parse(queue);
      // delete parsedQueue[index];
      if (parsedQueue.length === 1) {
        parsedQueue.pop();
      } else {
        parsedQueue.splice(index, 1);
      }
      localStorage.setItem('queue', JSON.stringify(parsedQueue));
    }
  };

  render() {
    const { locale } = this.props;
    const localeKey = locale || 'en';
    const messages = translations[localeKey];

    return (
      <ErrorBoundarys>
        <IntlProvider locale={localeKey} key={localeKey} messages={messages}>
          <div>
            <Switch>
              <Route path="/" exact component={KioskLayout} />
              <Route
                path="/SimpleStartKioskWelcomeScreen"
                component={SimpleStartKioskWelcomeScreenLayout}
              />
              <Route
                path="/ContactlessCapturePickup"
                component={ContactlessPickupLayout}
              />
              <Route path="/" exact component={KioskLayout} />
              <Route path="/KioskPickupCode" component={KioskPickupLayout} />
              <Route path="/KioskVerify" component={KioskVerifyMeLayout} />
              <Route
                path="/KioskConfirmPickup"
                component={KioskConfimPickupLayout}
              />
              <Route path="/KioskSignature" component={KioskSignatureMain} />
              <Route path="/KioskConsult" component={KioskConsultLayout} />
              <Route path="/KioskFeedback" component={KioskFeedbackLayout} />
              <Route
                path="/KioskCollectMedicines"
                component={KioskDoorLayout}
              />
              <Route
                path="/KioskConfirmUser"
                component={KioskCollectIDLayout}
              />

              <Route
                path="/KioskEnrollment"
                component={KioskEnrollmentLayout}
              />
              <Route path="/KioskOptHrs" component={KioskOptHrsMain} />
              <Route
                path="/KioskWelcomeScreen"
                component={KioskWelcomeScreenLayout}
              />
              <Route path="/KioskCheckin" component={KioskCheckinLayout} />
              <Route
                path="/KioskNewPatientEnrollment"
                component={KioskNewPatientEnrollmentLayout}
              />
              <Route
                path="/KioskCurrentPatientSearch"
                component={KioskCurrentPatientSearchLayout}
              />
              <Route
                path="/KioskCurrentPatientSearchResult"
                component={KioskCurrentPatientSearchResultLayout}
              />
              <Route
                path="/KioskNewPatientSearchResult"
                component={KioskNewPatientSearchResultLayout}
              />
              <Route
                path="/KioskPatientLogin"
                component={KioskPatientLoginLayout}
              />
              <Route
                path="/KioskPatientViewRx"
                component={KioskPatientViewRxLayout}
              />
              <Route
                path="/KioskHealthandinformation"
                component={KioskHealthandinformationLayout}
              />
              <Route path="/KioskGetKit" component={KioskGetKitLayout} />
              <Route path="/KioskRefillRx" component={KioskRefillRxLayout} />
              <Route path="/KioskShopNow" component={KioskShopNowLayout} />
              <Route
                path="/KioskProductList"
                component={KioskProductListLayout}
              />
              <Route path="/KioskQuizTest" component={KioskQuizTestLayout} />
              <Route path="/KioskQuizPass" component={KioskQuizPassLayout} />
              <Route
                path="/KioskQuizEnrollment"
                component={KioskQuizEnrollmentLayout}
              />
              <Route path="/KioskOrderItem" component={KioskOrderItemLayout} />
              <Route path="/AdminLogin" component={AdminLoginLayout} />
              <Route
                path="/SecureBadgeLogin"
                component={SecureBadgeLoginForm}
              />
              <Route path="/implicit" component={OktaLogin} />
              <Route path="/PatientLogin" component={PatientLoginLayout} />
              <Route path="/AdminPanel" component={AdminPanelLayout} />
              <Route path="/login" component={LoginLayout} />
              <Route path="/AdminStock" component={AdminPanelStockLayout} />
              <Route
                path="/AdminKioskVerify"
                component={AdminKioskVerifyLayout}
              />
              <Route
                path="/AdminMaintenance"
                component={AdminMaintenanceLayout}
              />
              <Route path="/AdminSettings" component={AdminSettingLayout} />
              <Route path="/AdminRemove" component={AdminRemoveLayout} />

              <Route
                path="/RearStock/AdminLogin"
                component={RearStockAdminLoginLayout}
              />
              <Route
                path="/RearStock/AdminStock"
                component={RearStockAdminPanelStockLayout}
              />
              <Route
                path="/RearStock/AdminPanel"
                component={RearStockAdminPanelLayout}
              />
              <Route
                path="/RearStock/AdminMaintenance"
                component={RearStockAdminMaintenanceLayout}
              />
              <Route
                path="/RearStock/AdminSettings"
                component={RearStockAdminSettingLayout}
              />
              <Route
                path="/RearStock/AdminRemove"
                component={RearStockAdminRemoveLayout}
              />
              <Route
                path="/RearStock/AdminKioskVerify"
                component={RearStockAdminKioskVerifyLayout}
              />
              <Route
                path="/KioskCheckConfirmuser"
                component={KioskCheckConfirmuserLayout}
              />
              <Route
                path="/KioskInformation"
                component={KioskInformationMain}
              />
            </Switch>
          </div>
        </IntlProvider>
      </ErrorBoundarys>
    );
  }
}

const mapStateToProps = ({ box, pollingStats }) => ({
  box,
  ...pollingStats
});

const mapDispatchToProps = dispatch => ({
  startPolling: params => dispatch(startPolling(params)),
  stopPolling: () => dispatch(stopPolling()),
  updatePollingStats: stats => dispatch(updatePollingStats(stats)),
  updateData: box => dispatch(updateData(box)),
  initialData: box => dispatch(initialData(box))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(App));

// export default withStyles(styles, { withTheme: true })(App);
