import { connect } from 'react-redux';
import AdminLoginForm from './AdminLoginForm';

import * as AdminLoginActions from './AdminLoginActionCreator';
import * as localeActions from './AdminLoginLocalActionCreator';

const mapStateToProps = state => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk =
    // state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
    state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminLoginActions,
  ...localeActions
};

const AdminLoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLoginForm);

export default AdminLoginFormContainer;
