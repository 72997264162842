const styles = theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    appLogoContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    },
    appLogo: {
      // width: '250px'
      width: '130px'
    },
    appName: {
      fontSize: '2.25em',
      fontWeight: '100',
      color: '#005d67'
    },
    title: {
      marginTop: '5em',
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC'
    },
    videoContainer: {
      height: '5em',
      width: '4em'
    },
    WebCamContainer: {
      marginTop: '6em'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    '@media only screen and (min-width: 1440px) and (orientation:landscape)': {
      appLogo: {
        width: '250px'
      },
      appLogoContainer: {
        display: 'flex',
        height: '140px',
        alignItems: 'center',
        width: '360px',
        justifyContent: 'center'
      },
      container: {
        display: 'grid',
        minHeight: '400px',
        textAlign: 'center',
        gridAutoFlow: 'dense',
        justifyContent: 'center',
        alignItems: 'center'
      },
      videoContainer: {
        height: 190,
        width: 360
      }
    },
    TimerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '5em'
    },
    Timer: {
      fontFamily: 'Montserrat',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    Value: {
      fontSize: '40px'
    },
    Text: {
      color: '#aaa'
    }
  };
};

export default styles;
