import REAR_BIN_MAPPING from './rear-bin-mapping';
const Cryptr = require('cryptr');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);

let init = () => {
  console.log('Board not connected');
};

let updatePaymentStatus;

// let wrapperObj = null;
let ipcRendererObj = null;
let openBin = binId => {
  console.log(`openBin called with ${binId}`);
  console.log('Board not connected');
};
let openMultipleBins = binIds => {
  console.log(`openMultipleBins called with ${binIds}`);
  console.log('Board not connected');
};
let writeCoolTemp = value => {
  console.log(`writeCoolTargetTemp with ${value}`);
  console.log('Board not connected');
};

let writeSettings = FileObj => {
  console.log(`writeSettings called with ${JSON.stringify(FileObj)}`);
};

let writeInitSettings = FileObj => {
  console.log(`writeInitSettings called with ${JSON.stringify(FileObj)}`);
};

let readSettings = () => {
  console.log(`readSettings called to get settings`);
};

let WritePickupInfo = pickupObject => {
  console.log(`WritePickupInfo called with ${JSON.stringify(pickupObject)}`);
};

let WriteInitPickupInfo = pickupObject => {
  console.log(
    `WriteInitPickupInfo called with ${JSON.stringify(pickupObject)}`
  );
};

let readPickupInfos = () => {
  console.log(`readSettings called to get settings`);
};

let forceBinStatusCheck = () => {
  console.log(`forceBinStatusCheck called to force check the door status`);
};

let POSHandler = (event, args) => {
  console.log(`event ${event} and args ${args}`);
};

let closeApplication = () => {
  console.log('Not running in electronjs');
  localStorage.removeItem('permissionUser');
  localStorage.removeItem('permissions');

  let n = sessionStorage.length;
  while (n--) {
    let key = sessionStorage.key(n);
    if (key) {
      sessionStorage.removeItem(key);
    }
  }
};

let authRequest = (
  transactionId,
  totalAmount,
  transactions,
  updatePaymentStatusFunction
) => {
  console.log(
    `writeToPOS called with command :  ${(transactionId,
    totalAmount,
    transactions)}`
  );
  console.log('Board not connected');
};

if (window.electron) {
  let { /* app, */ ipcRenderer, remote } = window.electron;
  // if (app) wrapperObj = app;
  if (ipcRenderer) ipcRendererObj = ipcRenderer;

  closeApplication = () => {
    remote.getCurrentWindow().close();
    localStorage.removeItem('permissionUser');
    localStorage.removeItem('permissions');

    let n = sessionStorage.length;
    while (n--) {
      let key = sessionStorage.key(n);
      if (key) {
        sessionStorage.removeItem(key);
      }
    }
  };

  writeSettings = FileObj => {
    const command = JSON.stringify({
      command: 'write',
      FileObj
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  writeInitSettings = FileObj => {
    const command = JSON.stringify({
      command: 'write-init',
      FileObj
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  WritePickupInfo = pickupObject => {
    const command = JSON.stringify({
      command: 'write-pickup-info',
      pickupObject
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  WriteInitPickupInfo = pickupObject => {
    const command = JSON.stringify({
      command: 'write-pickup-info-init',
      pickupObject
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  readSettings = () => {
    if (ipcRendererObj) {
      const fileData = ipcRenderer.sendSync('readSettings', 'read');
      return fileData;
    }
  };

  readPickupInfos = () => {
    if (ipcRendererObj) {
      const pickupData = ipcRenderer.sendSync('readPickupInfo', 'read');
      return pickupData;
    }
  };

  forceBinStatusCheck = () => {
    if (ipcRendererObj) {
      const fileData = ipcRenderer.sendSync('forceBinStatusCheck', 'all');
      return fileData;
    }
  };

  openBin = binId => {
    //Added By Kishan for Rear Screen Bin Mapping
    // Modified By : Belani Jaimin. Added Session Management. DI-1561: Kiosk> Create valid session alarm/flag and alert for invalid door openings
    const _activeSession = localStorage.getItem('ActiveSession');
    const decryptedSessionObject = _cryptr.decrypt(_activeSession);
    const parseddecryptedSessionObject = JSON.parse(decryptedSessionObject);
    if (
      parseddecryptedSessionObject &&
      parseddecryptedSessionObject._session_active === true
    ) {
      let rearStock;
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        rearStock = fileData ? fileData.rearStock : '';
      } else {
        rearStock =
          localStorage.getItem('rearStock') &&
          (localStorage.getItem('rearStock') === 'true' ||
            localStorage.getItem('rearStock') === -true)
            ? true
            : false;
      }
      if (rearStock) {
        console.log('Before:', binId);
        binId = REAR_BIN_MAPPING[binId];
        console.log('openBin-RearStock:', binId);
      } else {
        console.log('openBin-Front:', binId);
      }
      const command = JSON.stringify({
        command: 'open-bin',
        binId
      });
      if (ipcRendererObj) {
        ipcRendererObj.send('kiosk-to-main', command);
      }
      console.log(command);
    }
  };

  openMultipleBins = binIds => {
    const _activeSession = localStorage.getItem('ActiveSession');
    const decryptedSessionObject = _cryptr.decrypt(_activeSession);
    const parseddecryptedSessionObject = JSON.parse(decryptedSessionObject);
    if (
      parseddecryptedSessionObject &&
      parseddecryptedSessionObject._session_active === true
    ) {
      let rearStock;
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        rearStock = fileData ? fileData.rearStock : '';
      } else {
        rearStock =
          localStorage.getItem('rearStock') &&
          (localStorage.getItem('rearStock') === 'true' ||
            localStorage.getItem('rearStock') === -true)
            ? true
            : false;
      }
      // TODO: We have to add multiple bin opening for rear stocking process
      // if (rearStock) {
      //   console.log('Before:', binId);
      //   binId = REAR_BIN_MAPPING[binId];
      //   console.log('openBin-RearStock:', binId);
      // } else {
      //   console.log('openBin-Front:', binId);
      // }
      const command = JSON.stringify({
        command: 'open-multiple-bins',
        binIds
      });
      if (ipcRendererObj) {
        ipcRendererObj.send('kiosk-to-main', command);
      }
      console.log(command);
    }
  };

  writeCoolTemp = temperature => {
    const command = JSON.stringify({
      command: 'set-target-temp',
      temperature
    });
    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
    console.log(command);
  };

  authRequest = (
    transactionId,
    amount,
    transactions,
    updatePaymentStatusFunction
  ) => {
    const command = JSON.stringify({
      command: 'auth-request',
      transactionId,
      amount,
      transactions
    });
    updatePaymentStatus = updatePaymentStatusFunction;
    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
    console.log(command);
  };

  POSHandler = (event, args) => {
    console.log(args);
    // eslint-disable-next-line default-case
    switch (args.event) {
      case 'target-temperature-value':
        window.targetTempValue = args.data;
        break;
      case 'target-temperature-diff-value':
        window.targetTempDiffValue = args.data;
        break;
      case 'current-temperature':
        if (window.updateTemp) {
          window.updateTemp(args);
        }
        break;
      case 'swipe-expected':
        console.log(
          `SWIPE-EXPECTED is ${JSON.stringify(
            event
          )} and args are ${JSON.stringify(args)}`
        );
        updatePaymentStatus(
          'swipe-expected',
          'Please swipe your credit or debit card'
        );
        break;
      case 'authorizing':
        console.log(
          `AUTHORIZING is ${JSON.stringify(
            event
          )} and args are ${JSON.stringify(args)}`
        );
        updatePaymentStatus('authorizing', 'Authorizing...');
        break;
      case 'transaction':
        if (
          args.transctionApproved === true ||
          args.transctionApproved === 'true'
        ) {
          console.log(`TRANSACTION RESULT: Approved`);
          updatePaymentStatus('approved', 'Payment received.', args);
        } else {
          console.log(`TRANSACTION RESULT: Declined`);
          updatePaymentStatus('declined', 'Transaction failed.');
        }
        console.log(
          `event is ${JSON.stringify(event)} and args are ${JSON.stringify(
            args
          )}`
        );
        break;
      case 'current-board-data':
        console.log(`Current board data to kisok: ${JSON.stringify(args)}`);
        if (window.getBoardData) {
          window.getBoardData(args);
        }
        break;
    }
  };

  init = () => {
    if (ipcRendererObj) {
      ipcRendererObj.on('main-to-kiosk', (event, arg) => {
        // console.log('main-to-kiosk');
        // console.log(event);
        // console.log(arg);
        // console.log('message received by kiosk');
        POSHandler(event, arg);
      });
      console.log('Board initialized');
    } else {
      console.log('Board not initialized');
      console.log(ipcRendererObj);
    }
  };
}

window.writeCoolTemp = writeCoolTemp;

export {
  init,
  /* wrapperObj, */ ipcRendererObj,
  openBin,
  writeSettings,
  readSettings,
  writeInitSettings,
  closeApplication,
  authRequest,
  writeCoolTemp,
  forceBinStatusCheck,
  WritePickupInfo,
  WriteInitPickupInfo,
  readPickupInfos,
  openMultipleBins
};
