// snippet: rcep
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminRemoveFormControls.styles';
import TextField from '@material-ui/core/TextField';
import { injectIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'react-simple-keyboard/build/css/index.css';
import Keyboard from 'react-simple-keyboard';
import ASSIGN from '../../KioskLayout/assignmentStatus';
import EVENT_TYPES from '../../Common/event-type';
import EVENT_CRUD_TYPES from '../../Common/event-crud-types';
import EventLogHandler from '../../Common/event-log-handler';
import { openBin } from '../../../helpers/board';
import keyboardImg from '../AdminLayout/keyboard.png';

const orderItem = {};
const customerInfo = {
  id: '',
  name: '',
  rxNo: '',
  patient_id: ''
};

const pickupConfig = {
  firstName: '',
  lastName: '',
  emailId: '',
  companyLogo: '',
  companyName: '',
  companyContact: '',
  adressLine1: '',
  adressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  orderHtml: '',
  orderLocation: '',
  boxName: '',
  boxAddress: '',
  pharmAddress: {},
  qrCode: '',
  pickupCode: '',
  isDisplay: 'display',
  orders: [
    {
      orderId: '',
      pickupCode: '',
      items: []
    }
  ]
};

export class AdminRemoveFormControls extends Component {
  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showKeyboard: false,
      patientDetails: [],
      stockCode: '',
      displayMsg: '',
      maxlength: 6,
      openOnReject: false,
      layoutName: 'default',
      SelectedInput: 'stockCode',
      puCode: ''
    };
    this.textInput = React.createRef();
    this.keyboardHandler = this.keyboardHandler.bind(this);
  }

  handleClickOpen = () => {
    this.state.patientDetails.forEach(x => {
      pickupConfig.firstName = x.first_name;
      pickupConfig.lastName = x.last_name;
      x.orders.forEach(y => {
        openBin(y.bin_id);
        pickupConfig.orderId = y.id;
        y.items.forEach(z => {
          orderItem.rxNo = z.rx_no;
          customerInfo.rxNo = z.rx_no;
          orderItem.qty = z.qty;
          orderItem.CoPayAmount = z.price;
          pickupConfig.orders[0].items.push(orderItem);
        });
      });
      customerInfo.id = x.id;
      customerInfo.name = x.first_name + ' ' + x.last_name;
      customerInfo.patient_id = x.patient_id;
    });

    this.setState({ open: true });
  };

  handleNo = () => {
    this.setState({ open: false });
  };

  Removeevent = async orderid => {
    try {
      const event_params = {
        event_name: EVENT_CRUD_TYPES.UPDATE,
        event_type_name: EVENT_TYPES.REMOVE_RX,
        description:
          'Prescription is Remove with stock code ' +
          this.state.stockCode +
          ' with Rx No ' +
          customerInfo.rxNo,
        object_id: ASSIGN.REMOVE_RX,
        tofor_id: orderid,
        box_id: this.props.box.id
      };
      new EventLogHandler().handleEventLog(event_params);
    } catch (err) {
      console.error(err);
    }
  };

  handleYes = () => {
    if (this.props && this.props.box && this.props.box.customers) {
      const filteredArray = this.props.box.customers.filter(element =>
        element.orders.some(
          subElement =>
            subElement.stock_code.toString().toLowerCase() ===
            this.state.stockCode.toString().toLowerCase()
        )
      );
      const updateStatusOfRxObj = {
        id: filteredArray[0].orders[0].id,
        is_deleted: true,
        type_id: ASSIGN.REMOVE_RX,
        b_id: filteredArray[0].orders[0].id.bin_id
      };
      fetch(process.env.REACT_APP_BASEURL + 'updateStatusOfRemoveRX', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateStatusOfRxObj)
      }).then(response => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      });
      document.querySelector('#' + this.state.SelectedInput).value = '';
      this.setState({
        patientDetails: [],
        stockCode: '',
        displayMsg: '',
        open: false
      });
      setTimeout(() => {
        this.textInput.current.focus();
      }, 650);
      this.Removeevent(filteredArray[0].orders[0].id);
    }
    this.keyboard.keyboard.clearInput();
  };
  onChange = input => {
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(event);
    }
  };
  onKeyPress = button => {
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };
  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };
  change = event => {
    const stockCode = event.target.value;
    if (this.props && this.props.box) {
      let customer;
      customer = this.props.box.customers.filter(
        x =>
          x &&
          x.orders &&
          x.orders.filter(
            y =>
              y &&
              y.stock_code.toString().toLowerCase() ===
                stockCode.toString().toLowerCase() &&
              y.type_id === ASSIGN.STOCKED
          ).length > 0
      )[0];

      let filteredArray = [];

      if (customer) {
        const order = customer.orders.filter(
          x =>
            x &&
            x.stock_code.toString().toLowerCase() ===
              stockCode.toString().toLowerCase() &&
            x.type_id === ASSIGN.STOCKED
        );
        customer.orders = order;
        filteredArray = [customer];
        if (order.length > 0) {
          this.setState({ showKeyboard: false });
        }
        this.setState({
          patientDetails: filteredArray,
          stockCode: stockCode,
          displayMsg: 'Please enter valid stock code.'
        });
      } else {
        this.setState({
          patientDetails: [],
          pickupCode: '',
          displayMsg: 'Please enter valid stock code.'
        });
      }
    }
  };

  Focus = obj => {
    this.setState({ showKeyboard: true });
    let ref = obj.target.name;
    this.setState({ SelectedInput: ref });
  };

  renderPatientName() {
    const patient = this.state && this.state.patientDetails[0];
    const firstName = patient.first_name.charAt(0);
    const lastName = patient.last_name.substring(0, 3);
    return firstName + '. ' + lastName;
  }

  renderCount() {
    const patient = this.state && this.state.patientDetails[0];
    const lengthCount = patient && patient.orders[0].items.length;
    return lengthCount;
  }
  keyboardHandler() {
    this.setState({
      showKeyboard: !this.state.showKeyboard
    });
  }
  render() {
    const { classes, handleBack } = this.props;
    const { patientDetails, stockCode, displayMsg } = this.state;
    // const pickupCode = patientDetails;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#005d67'
        }
      },
      typography: { useNextVariants: true }
    });

    return (
      <div className={classNames(classes.container)}>
        <MuiThemeProvider theme={theme}>
          <TextField
            style={{ textAlign: 'center' }}
            id="stockCode"
            name="stockCode"
            className={classes.textField}
            defaultValue=""
            margin="normal"
            variant="outlined"
            placeholder="Scan or Enter your code here"
            inputProps={{ maxLength: 6 }}
            autoComplete="off"
            onChange={e => this.change(e)}
            autoFocus
            inputRef={this.textInput}
            onFocus={this.Focus.bind('stockCode')}
            // onBlur={this.Blur.bind('pickupCode')}
            onKeyPress={ev => {
              // console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === 'Enter') {
                this.change(ev);
                // ev.preventDefault();
              }
            }}
          />
        </MuiThemeProvider>
        {this.state && this.state.patientDetails.length > 0 ? (
          <div>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableBody>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableEmptyCell}>
                    &nbsp;
                  </TableCell>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    Patient
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {this.renderPatientName()}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.PatientInfoTableRow}>
                  <TableCell className={classes.PatientInfoTableEmptyCell}>
                    &nbsp;
                  </TableCell>
                  <TableCell className={classes.PatientInfoTableFilledCell}>
                    Code
                  </TableCell>
                  <TableCell className={classes.cellBorder}>
                    {stockCode.toUpperCase()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classNames(classes.PatientInfoTable)}>
              <TableHead className={classNames(classes.TableHead)}>
                <TableRow>
                  <TableCell
                    className={classNames(
                      classes.RemoveIcon,
                      classes.cellBorder
                    )}
                  >
                    &nbsp;
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.CodeClass,
                      classes.cellBorder
                    )}
                  >
                    Drug Name
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PrescriptionClass,
                      classes.cellBorder
                    )}
                  >
                    Rx No.
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.PrescriptionClass,
                      classes.cellBorder
                    )}
                  >
                    Qty
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(patientDetails && patientDetails).map(
                  (pres, i) => {
                    return patientDetails[pres].orders.map((order, idx) => {
                      return patientDetails[pres].orders[idx].items.map(
                        (task, idx) => {
                          return (
                            <TableRow
                              key={task.id}
                              className={classes.PatientInfoTableRow}
                            >
                              {/* <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                <img src={task.image} alt="Not Found" />
                              </TableCell>
                              {/* <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {this.renderPatientName(patientDetails)}
                              </TableCell> */}
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                &nbsp;
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.drug_name}
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.rx_no}
                              </TableCell>
                              <TableCell
                                className={classNames(classes.fontSizes)}
                              >
                                {task.qty}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      );
                    });
                  }
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className={classNames(classes.Instructiontitle)}>
            {displayMsg && displayMsg}
          </div>
        )}
        {this.state && this.state.patientDetails.length > 0 && (
          <Table className={classNames(classes.PatientInfoTable)}>
            <TableBody>
              <TableRow className={classes.PatientInfoTableRow}>
                <TableCell
                  colSpan={5}
                  className={classNames(
                    classes.PatientInfoTableEmptyCell,
                    classes.cellBorderInfo
                  )}
                >
                  Total RX in this code ({this.renderCount()})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton)}
            onClick={handleBack}
          >
            Back
          </Button>

          <img
            src={keyboardImg}
            style={{ height: '55px', marginTop: '1em', cursor: 'pointer' }}
            alt=""
            onClick={this.keyboardHandler}
          />

          <Button
            type="button"
            color="primary"
            variant="contained"
            // className={classNames(classes.primaryButton)}
            onClick={this.handleClickOpen}
            className={
              this.state && this.state.patientDetails.length === 0
                ? classNames(classes.disabledPrimaryButton)
                : classNames(classes.primaryButton)
            }
            disabled={this.state && this.state.patientDetails.length === 0}
          >
            Remove
          </Button>
        </div>
        <div>
          <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.open}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
              style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
            }}
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  Remove confirmation
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                You have removed this order {stockCode.toUpperCase()}
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleYes}
                  className={classNames(classes.buttonColor)}
                >
                  Yes
                </Button>
                <Button
                  onClick={this.handleNo}
                  className={classNames(classes.buttonColor)}
                >
                  No
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div>
          {/* <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={this.state.openOnReject}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div className={classNames(classes.dialogBackground)}>
              <DialogTitle id="alert-dialog-title">
                <span className={classNames(classes.dialogTitleColor)}>
                  Scan confirmation
                </span>
              </DialogTitle>
              <DialogContent className={classNames(classes.dialogContentColor)}>
                This code is on hold, please remove it and return it to the
                pharmacy.
              </DialogContent>
              <DialogActions className={classNames(classes.alignButton)}>
                <Button
                  onClick={this.handleRejectOk}
                  className={classNames(classes.buttonColor)}
                >
                  Ok
                </Button>
              </DialogActions>
            </div>
          </Dialog> */}
        </div>
        <div
          className={classNames(
            this.state.showKeyboard
              ? classes.simpleKeyboardShow
              : classes.simpleKeyboard
          )}
        >
          <Keyboard
            ref={r => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={input => this.onChange(input)}
            onKeyPress={button => this.onKeyPress(button)}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminRemoveFormControls)
);
