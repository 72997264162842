const styles = theme => {
  return {
    root: {
      // backgroundColor: theme.palette.background.paper
    },
    homeRoot: {}
  };
};

export default styles;
