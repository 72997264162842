const styles = theme => {
  return {
    container: {
      display: 'grid',
      minHeight: '75vh',
      gridAutoFlow: 'dense',
      justifyContent: 'center',
      minWidth: '30vh'
      //alignItems: 'center'
    },
    appLogoContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      height: '80px',
      // alignItems: 'flex-end',
      justifyContent: 'center'
    },
    appLogo: {
      width: '290px'
    },
    appName: {
      fontSize: '3em',
      fontWeight: '400',
      color: '#005d67'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '100',
      color: '#005d67'
    },
    form: {
      width: '50vh',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '50vh',
      overflow: 'auto'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      /*border is optional*/
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    '@media screen and  (max-width: 1080px)': {
      appLogoContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        height: '80px',
        alignItems: 'center',
        justifyContent: 'center'
      },
      appLogo: {
        width: '200px'
      },
      container: {
        maxWidth: '50vh',
        minHeight: '55vh',
        display: 'grid',
        position: 'relative',
        margin: 'auto',
        justifyContent: 'center'
        //alignItems: 'center'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)': {
      container: {
        display: 'grid',
        minHeight: 'initial',
        overflowY: 'auto',
        height: 'auto',
        width: '800px'
      },
      form: {
        width: '700px'
      }
    }
  };
};

export default styles;
