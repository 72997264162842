import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskRefillRxLayout.styles';
import {
  KioskWelcomeFooter,
  KioskWelcomeHeader,
  KioskRefillRxMain,
  KioskIdelScreen
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
export class KioskRefillRxLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: 'Enrollment'
  };

  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes } = this.props;
    var customFooterBottom;
    if (window.screen.width >= 1440) {
      customFooterBottom = '-25%';
    } else {
      customFooterBottom = '0%';
    }

    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <KioskWelcomeHeader />
        <KioskRefillRxMain />
        <KioskWelcomeFooter customFooterBottom={customFooterBottom} />
        <KioskIdelScreen />
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskRefillRxLayout);
