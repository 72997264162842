const styles = theme => {
  return {
    myContent: {
      width: '56vh',
      height: 120,
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
      margin: 'auto auto 1%',
      padding: '4%'
    },
    title: {
      color: '#fafafa',
      fontSize: '30px',
      textAlign: 'center',
      fontWeight: '300',
      fontFamily: 'Roboto'
    },
    subtitle: {
      color: '#fafafa',
      fontSize: '2em',
      textAlign: 'center',
      fontWeight: '500',
      fontFamily: 'Roboto'
    },
    '@media screen and (max-width: 1080px)': {
      myContent: {
        width: '50vh',
        height: 120,
        background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
        margin: 'auto auto 1%',
        padding: '5%'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)': {
      myContent: {
        width: '950px',
        height: '100px !important',
        background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
        margin: 'auto auto 1%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      subtitle: {
        fontSize: '1.5em'
      }
    }
  };
};

export default styles;
