import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { addLocaleData } from 'react-intl';
// import PropTypes from 'prop-types';
import AppContainer from './AppContainer';
import { SnackbarProvider } from 'notistack';
// import translations from './i18n/locales';
// import { connect } from 'react-redux';
// import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';

// addLocaleData(en);
addLocaleData(es);

// const locale = window.location.search.replace('?locale=', '') || 'en';
// const messages = translations[locale];

// https://material-ui.com/customization/themes/
const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  footerBackgroundColor: '#394242',
  footerTextColor: '#aaa',
  footerFontSize: '0.90rem'
});

class AppWrapper extends React.Component {
  // componentWillMount() {
  //   const locale =
  //     (this.props.store &&
  //       this.props.store
  //         .getState()
  //         .get('locale')
  //         .get('lang')) ||
  //     'en';
  //   const messages = translations[locale];
  //   this.setState({ locale, messages });
  // }

  render() {
    return (
      // <IntlProvider
      //   locale={this.state && this.state.locale}
      //   key={this.state && this.state.locale}
      //   messages={this.state && this.state.messages}
      // >
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={5}>
            <AppContainer
              store={this.props.store}
              history={this.props.history}
            />
          </SnackbarProvider>
        </MuiThemeProvider>
      </React.Fragment>
      // </IntlProvider>
    );
  }
}

// AppWrapper.defaultProps = {
//   locale: 'en'
// };

// AppWrapper.propTypes = {
//   locale: PropTypes.string
// };

// const mapStateToProps = state => {
//   const locale =
//     state.get('locale') &&
//     state.get('locale').toJS &&
//     state.get('locale').toJS();
//   return {
//     ...locale
//   };
// };
// export default connect(mapStateToProps)(AppWrapper);
export default AppWrapper;
