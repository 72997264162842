import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskCollectIDLayout.styles';
import step from '../Common/show_hide_steps';
import { readKioskSettings } from '../Common/skipSteps';
// import { Paper } from '@material-ui/core';
import {
  //KioskSideSlides,
  KioskFooter,
  KioskHeader,
  KioskCollectIDMain,
  KioskIdelScreen
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import { injectIntl, defineMessages } from 'react-intl';

let FileObje = null;
let stepTitle = step.step6;

const messages = defineMessages({
  LetUsKnow: {
    id: 'PickingUp.LetUsKnow',
    defineMessages: 'Let us know who’s picking up'
  },
  Steps: {
    id: 'Verify.Steps',
    defineMessages: 'Step '
  }
});
export class KioskCollectIDLayout extends Component {
  constructor(props) {
    super(props);
    FileObje = readKioskSettings(2);
    if (FileObje !== null) {
      let index = FileObje.steps.findIndex(
        x => x.value === true && x.step === step.step6
      );
      if (index > -1) {
        let res = FileObje.steps[index];
        if (res.value === true) {
          stepTitle = res.stepTitle;
        }
      }
    }
    this.state = {
      stepTitle:
        this.props &&
        this.props.intl.formatMessage(messages.Steps) + stepTitle + ' :',
      pageTitle:
        this.props && this.props.intl.formatMessage(messages.LetUsKnow),
      customTop: '6%',
      customHeight: '120px'
    };
  }

  static propTypes = {
    test: PropTypes.string
  };

  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes } = this.props;
    var customFooterBottom;
    if (window.screen.width === 1080) {
      customFooterBottom = '0%';
    } else if (window.screen.width === 1440 || window.screen.width === 1920) {
      customFooterBottom = '0%';
    } else {
      customFooterBottom = '0%';
    }
    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <KioskHeader />
        {/* <KioskSideSlides
          stepTitle={this.state.stepTitle}
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={this.state.customHeight}
        /> */}

        <KioskCollectIDMain />
        <KioskFooter
          customFooterBottom={customFooterBottom}
          isLanguageBarAllowd={true}
        />
        <KioskIdelScreen />
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(KioskCollectIDLayout)
);
