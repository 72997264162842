import React from 'react';

const MainLogo = props => {
  const { box } = props;
  let brand = JSON.parse(localStorage.getItem('brands'));
  try {
    if (brand.length > 0) {
      //return <img alt="logo" height="85px" src={brand[0].brand_logo_url} />;
      if (
        brand[0].brand_logo_url !== null &&
        brand[0].brand_logo_url !== 'null' &&
        brand[0].brand_logo_url !== undefined &&
        brand[0].brand_logo_url !== 'undefined' &&
        brand[0].brand_logo_url !== ''
      ) {
        return <img alt="logo" height="85px" src={brand[0].brand_logo_url} />;
      } else {
        return <img alt="" height="85px" />;
      }
    } else if (box) {
      if (
        box.account.brand.logo_url !== null &&
        box.account.brand.logo_url !== 'null' &&
        box.account.brand.logo_url !== undefined &&
        box.account.brand.logo_url !== 'undefined' &&
        box.account.brand.logo_url !== ''
      ) {
        return (
          <img alt="logo" height="85px" src={box.account.brand.logo_url} />
        );
      } else {
        return <img alt="" height="85px" />;
      }
    } else {
      return <img alt="" height="85px" />;
    }
  } catch (e) {
    return <img alt="" height="85px" />;
  }
};

export default MainLogo;
