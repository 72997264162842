const styles = theme => {
  return {
    mainContainer: {
      maxWidth: '56vh',
      maxHeight: '75vh',
      display: 'grid',
      position: 'relative',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    container: {
      display: 'grid',
      minHeight: '75vh',
      gridAutoFlow: 'dense',
      justifyContent: 'center',
      minWidth: '50vh'
    },
    formContainer: {
      display: 'grid',
      gridGap: '1em',
      alignItems: 'center'
    },
    appLogoContainer: {
      display: 'flex',
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginTop: '-50px',
      height: '100px'
    },
    appLogo: {
      width: '200px'
    },
    appName: {
      fontSize: '3.5em',
      color: '#2F5597',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      fontSize: '1.5em',
      color: '#2F5597',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'grid'
    },
    form: {
      width: '50vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    leftFormContent: {
      // marginTop: '-70px'
      marginTop: '-100px'
    },
    primaryButton: {
      marginTop: '1em',
      color: '#fff',
      fontSize: '20px',
      width: '45%',
      // height: '80px',
      // lineHeight: '20px',
      background: ' #2F5597',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: ' #2F5597'
      }
    },
    primaryButtonNewpassword: {
      marginTop: '1em',
      color: '#fff',
      fontSize: '20px',
      width: '100%',
      background: ' #2F5597',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: ' #2F5597'
      }
    },
    disabledPrimaryButton: {
      marginTop: '1em',
      fontSize: '20px',
      width: '45%',
      // height: '80px',
      border: '1px solid  #2F5597',
      borderRadius: '10px'
    },
    backButton: {
      marginTop: '1em',
      fontSize: '20px',
      width: '30%',
      height: '50px',
      lineHeight: '20px',
      background: '#fff',
      color: '#aaa',
      border: '1px solid  #2F5597',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#aaa'
      }
    },
    formLinkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    codeGrid: {
      display: 'grid',
      //
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '2em'
    },
    simpleKeyboard: {
      width: '640px',
      position: 'absolute',
      left: '5%',
      bottom: '-20%',
      zIndex: 999
    },
    buttonContainer: {
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      // justifyContent: 'space-between',
      justifyContent: 'space-evenly',
      position: 'absolute',
      width: '82%',
      bottom: '3%',
      left: '9%'
    },
    invalid: {
      color: 'red'
    },
    valid: {
      color: 'green'
    },
    disabledPrimaryButtonNewpassword: {
      width: '100%',
      marginTop: '1em',
      fontSize: '20px'
    },
    '@media (max-width: 640px)': {
      mainContainer: {
        gridTemplateColumns: '320px'
      }
    },
    '@media screen and (max-width: 1080px)': {
      mainContainer: {
        maxWidth: '50vh',
        maxHeight: '56vh',
        display: 'grid',
        position: 'relative',
        // margin: 'auto',
        marginTop: '80px',
        justifyContent: 'center',
        alignItems: 'center'
      },
      container: {
        display: 'grid',
        minHeight: '56vh',
        gridAutoFlow: 'dense',
        justifyContent: 'center',
        minWidth: '50vh'
      },
      form: {
        width: '40vh',
        alignItems: 'center',
        justifyContent: 'center'
      },
      simpleKeyboard: {
        width: '50vh',
        position: 'absolute',
        left: '0%',
        bottom: '-34%',
        zIndex: 999
      }
    },
    '@media screen and (max-width: 1440px) and (orientation:landscape)': {
      mainContainer: {
        maxWidth: '950px',
        maxHeight: '40vh'
      },
      container: {
        display: 'flex',
        minHeight: '47vh',
        flexDirection: 'row',
        minWidth: '800px',
        justifyContent: 'center'
      },
      form: {
        width: '100%'
      },
      appName: {
        fontSize: '2em',
        margin: '9px',
        display: 'none'
      },
      Instructiontitle: {
        fontSize: '1.5em',
        fontWeight: '500',
        color: '#82C2AC',
        display: 'grid',
        justifyContent: 'center',
        margin: '10px',
        marginBottom: '15px'
      },
      leftFormContent: {
        width: '50%'
      },
      simpleKeyboard: {
        width: '100%',
        position: 'absolute',
        left: '0%',
        right: '0%',
        zIndex: 999,
        bottom: '-25vh'
      },
      primaryButton: {
        width: '40%',
        marginTop: '0em',
        background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
        fontSize: '1em'
      },
      disabledPrimaryButton: {
        width: '40%',
        marginTop: '0em',
        fontSize: '1em'
      },
      formFooter: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: '12px',
        left: '0',
        right: '0',
        width: '700px',
        margin: '0 auto'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)': {
      mainContainer: {
        maxWidth: '950px',
        maxHeight: '40vh'
      },
      container: {
        display: 'flex',
        minHeight: '47vh',
        flexDirection: 'row',
        minWidth: '800px',
        justifyContent: 'space-between'
      },
      form: {
        width: '100%'
      },
      appName: {
        fontSize: '2em',
        margin: '9px',
        display: 'none'
      },
      Instructiontitle: {
        fontSize: '1.5em',
        fontWeight: '500',
        color: '#82C2AC',
        display: 'grid',
        justifyContent: 'center',
        margin: '10px',
        marginBottom: '15px'
      },
      leftFormContent: {
        width: '50%'
      },
      simpleKeyboard: {
        width: '100%',
        position: 'absolute',
        left: '0%',
        right: '0%',
        zIndex: 999,
        bottom: '-29vh'
      },
      primaryButton: {
        width: '40%',
        marginTop: '0em',
        background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
        fontSize: '1em'
      },
      disabledPrimaryButton: {
        width: '40%',
        marginTop: '0em',
        fontSize: '1em'
      },
      formFooter: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: '12px',
        left: '0',
        right: '0',
        width: '700px',
        margin: '0 auto'
      }
    }
  };
};

export default styles;

// backButton: {
//   marginTop: '-12em',
//   fontSize: '20px',
//   width: '42%',
//   height: '50px',
//   lineHeight: '20px',
//   background: '#fff',
//   color: '#aaa',
//   border: '1px solid  #2F5597',
//   borderRadius: '10px',
//   '&:hover': {
//     backgroundColor: '#fff',
//     color: '#aaa'
//   }
// },
// buttonContainer: {
//   flexGrow: '1',
//   display: 'flex',
//   alignItems: 'center',
//   flexDirection: 'row',
//   // justifyContent: 'space-between',
//   justifyContent: 'space-evenly',
//   position: 'absolute',
//   width: '82%',
//   bottom: '3%',
//   left: '9%'
// },
// Scantitle: {
//   // fontSize: '1.46em',
//   // fontWeight: '500',
//   // color: '#82C2AC'
//   fontSize: '1em',
//   color: ' #2F5597'
// },
// spinner: {
//   position: 'absolute',
//   background: 'rgba(255,255,255,.4)',
//   width: '100%',
//   height: '100%',
//   zIndex: '9999',
//   top: 0,
//   left: 0
// },
// spinnerImage: {
//   top: 'calc(50% - 32.5px)',
//   left: 'calc(50% - 65px)',
//   position: 'absolute',
//   width: '160px',
//   userSelect: 'none'
// },
// ButtonAlign: {
//   justifyContent: 'center'
// },
// disabledbackButton: {
//   marginTop: '3em',
//   width: '40%',
//   height: '50px',
//   fontSize: '20px',
//   // background:'#fff',
//   // color:'#aaa',
//   border: '1px solid  #2F5597',
//   borderRadius: '10px',
//   lineHeight: '20px',
//   backgroundColor: 'rgba(0, 0, 0, 0.12)'
// },
