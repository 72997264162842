// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminMaintancenceFormControls.styles';
import Image1 from './AdminPanelImages/1.png';
import Deactivate from './AdminPanelImages/deactivate.png';
import TestCommunication from './AdminPanelImages/test_communication.png';
import Grid from '@material-ui/core/Grid';
import { Paper, Button } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { openBin } from '../../../helpers/board';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

let nums = Array.from(Array(5).keys());
let nums1 = Array.from(Array(12).keys());
let elem = ['A', 'B', 'C', 'D', 'E'];
var selectedBins = [];
let j = 0;

export class AdminMaintancenceFormControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boxid: '',
      popupShow: false,
      disablebins: ''
    };
  }

  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };

  componentDidMount() {
    window._this = this;
    if (this.props.box !== null && this.props.box !== undefined) {
      const boxesid = this.props.box.id;
      this.setState({ boxid: boxesid });

      let bins = this.props.box.disabled_bins;
      if (bins !== null && bins !== undefined) {
        bins = bins.replace("'", '');
        let selbins = [];
        selbins = bins.split(',');
        for (j = 0; j < selbins.length; j++) {
          var items = document.getElementById(selbins[j]);
          items.style.background =
            'linear-gradient(155deg, #ce907b 0%,#FF0000 9%)';
        }
      }
    }
  }

  closeApp = () => {
    window.close();
  };

  openAllBin() {
    let Ids = [];
    nums1.forEach(n1 => {
      nums.forEach(n => {
        if ((elem[n] === 'B' || elem[n] === 'C') && (n1 >= 0 && n1 <= 6)) {
        } else {
          if (elem[n] === 'A') {
            Ids.push(elem[n] + '' + (n1 + 1));
          } else if (elem[n] === 'B' && (n1 >= 7 && n1 <= 11)) {
            Ids.push(elem[n] + '' + (n1 + 1));
          } else if (elem[n] === 'C' && (n1 >= 7 && n1 <= 11)) {
            Ids.push(elem[n] + '' + (n1 + 1));
          } else {
            Ids.push(elem[n] + '' + (n1 + 1));
          }
        }
      });
    });

    const filteredArray = window._this.sortArray(Ids);
    for (let index = 0; index < filteredArray.length; index++) {
      const element = filteredArray[index];
      console.log('Queuing ' + element);
      setTimeout(() => {
        openBin(element);
        var items = document.getElementById(element);
        items.style.background =
          'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
      }, index * 1000 + 1000);
    }
  }

  sortArray(Ids) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;

    function sortAlphaNum(a, b) {
      var aA = a.replace(reA, '');
      var bA = b.replace(reA, '');
      if (aA === bA) {
        var aN = parseInt(a.replace(reN, ''), 10);
        var bN = parseInt(b.replace(reN, ''), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    }
    return Ids.sort(sortAlphaNum);
  }

  deactivatebin = () => {
    const bins = [];
    const id = this.props.box.id;
    let varname = '';
    for (var i = 0; i < selectedBins.length; i++) {
      var items = document.getElementById(selectedBins[i]);
      items.style.background = 'linear-gradient(155deg, #ce907b 0%,#FF0000 9%)';
      bins.push(selectedBins[i]);
      if (i !== selectedBins.length - 1) {
        varname += selectedBins[i] + ',';
      } else {
        varname += selectedBins[i] + ',';
      }
    }
    let obj = {
      disabled_bins: varname,
      id: id
    };
    fetch(process.env.REACT_APP_BASEURL + 'boxs', {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
    }).then(response => {
      try {
        return response.text();
      } catch (error) {
        return null;
      }
    });
    this.setState({ popupShow: false });
  };

  openbin = binId => {
    openBin(binId);
    selectedBins.push(binId);
    var items = document.getElementById(binId);
    items.style.background = 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
    // const selectedBins = this.state.selectedBins;
    // const isCopied = selectedBins.filter(item => {
    //   if (item === binId) {
    //     return true;
    //   }
    // });
    // if (selectedBins.length === 0) {
    //   this.setState(prevState => ({
    //     selectedBins: [...prevState.selectedBins, binId]
    //   }));
    //   var itemss = document.getElementById(binId);
    //   itemss.style.background =
    //     'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
    // } else {
    //   if (isCopied.length === 0) {
    //     this.setState(prevState => ({
    //       selectedBins: [...prevState.selectedBins, binId]
    //     }));
    //     // to highlight the activated bin
    //     if (this.state && this.state.selectedBins) {
    //       var items = document.getElementById(binId);
    //       for (var i = 0; i < this.state.selectedBins.length; i++) {
    //         items.style.background =
    //           'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)';
    //       }
    //     }
    //   } else {
    //     this.setState({
    //       selectedBins: this.state.selectedBins.filter(function(item) {
    //         return item !== binId;
    //       })
    //     });

    //     var removedItem = document.getElementById(binId);
    //     removedItem.style.background =
    //       'linear-gradient(148deg, #ffffff 30%, #d9dcdb 60%, #dcdcdc 87%)';
    //   }
    // }

    // for (let index = 0; index < this.state.selectedBins.length; index++) {
    //   const element = this.state.selectedBins[index];
    //   if (this.state.selectedBins.length === 0) {
    //     openBin(element);
    //   } else {
    //     setTimeout(openBin(element), 650);
    //   }
    // }
  };
  deactivateBinPopup = () => {
    this.setState({ popupShow: true });
  };
  closePopup = () => {
    this.setState({ popupShow: false });
  };

  render() {
    const { classes, handleBack, box } = this.props;

    if (j === 0 && box !== null && box !== undefined) {
      this.setState({ boxid: box.id });
      j++;
      this.binId = box;
    }

    let SelElem = selectedBins.map((sElem, index) => {
      if (index === 0) {
        return <span>{sElem}</span>;
      } else {
        return <span>{', ' + sElem}</span>;
      }
    });
    return (
      <div className={classNames(classes.container)}>
        <Dialog
          maxWidth={'lg'}
          fullWidth={true}
          open={this.state.popupShow}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
          }}
        >
          <div className={classNames(classes.dialogBackground)}>
            <DialogContent className={classNames(classes.dialogContentColor)}>
              Are You Sure Want to Deactivate {SelElem} bin?
            </DialogContent>
            <DialogActions className={classNames(classes.alignButton)}>
              <Button
                onClick={this.closePopup}
                className={classNames(classes.buttonColor)}
              >
                {' '}
                NO
              </Button>
              <Button
                onClick={this.deactivatebin}
                className={classNames(classes.buttonColor)}
              >
                {' '}
                YES
              </Button>
            </DialogActions>
          </div>
        </Dialog>

        {/*  =========================   For Rear Stock Screen   ========================= */}
        <div className={classNames(classes.navContainer)}>
          <div className={classNames(classes.navItem)}>
            <div
              className={classNames(classes.navItemTop)}
              onClick={this.openAllBin}
            >
              <img
                src={Image1}
                alt="Stock"
                className={classNames(classes.navItemTopImg)}
              />
            </div>
            <p className={classNames(classes.navItemText)}>Open All Doors</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <div
              className={classNames(classes.navItemTop)}
              onClick={this.deactivateBinPopup}
            >
              <img
                src={Deactivate}
                alt="Deactivate"
                className={classNames(classes.navItemTopImg)}
              />
            </div>
            <p className={classNames(classes.navItemText)}>De-Activate Bin</p>
          </div>

          <div className={classNames(classes.navItem)}>
            <div className={classNames(classes.navItemTop)}>
              <img
                src={TestCommunication}
                alt="TestCommunication"
                className={classNames(classes.navItemTopImg)}
              />
            </div>
            <p className={classNames(classes.navItemText)}>
              Test Communication
            </p>
          </div>
        </div>

        {/*  =========================   For Kiosk Screen   ========================= */}
        <div className={classNames(classes.flexContainer)}>
          <div
            onClick={this.openAllBin}
            className={classNames(classes.flexItem)}
          >
            <img
              className={classNames(classes.imgProperty)}
              src={Image1}
              alt="Stock"
            />
          </div>
          <div
            onClick={this.deactivateBinPopup}
            className={classNames(classes.flexItem)}
          >
            <img
              className={classNames(classes.imgProperty)}
              src={Deactivate}
              alt="Remove"
            />
          </div>
          <div className={classNames(classes.flexItem)}>
            <img
              className={classNames(classes.imgProperty)}
              src={TestCommunication}
              alt="Verify"
            />
          </div>
        </div>

        <div className={classNames(classes.flexContainer)}>
          <div className={classNames(classes.flexTemText)}>Open All Doors</div>
          <div className={classNames(classes.flexTemText)}>De-Activate Bin</div>
          <div className={classNames(classes.flexTemText)}>
            Test Communication
          </div>
        </div>

        <div className={classNames(classes.ratingContainer)}>
          {nums1.map(n1 => {
            return (
              <Grid
                key={n1}
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={8}
                className={classNames(classes.RemoveMargin)}
              >
                {nums.map(n => {
                  if (
                    (elem[n] === 'B' || elem[n] === 'C') &&
                    (n1 >= 0 && n1 <= 6)
                  ) {
                    if (elem[n] === 'B') {
                      return (
                        <Grid
                          id={elem[n] + '' + (n1 + 1)}
                          onClick={() => this.openbin(elem[n] + '' + (n1 + 1))}
                          key={n}
                          item
                          xs={3}
                        />
                      );
                    } else {
                      return (
                        <Grid
                          id={elem[n] + '' + (n1 + 1)}
                          onClick={() => this.openbin(elem[n] + '' + (n1 + 1))}
                          key={n}
                          item
                          xs={2}
                        />
                      );
                    }
                  } else {
                    if (elem[n] === 'A' || elem[n] === 'B') {
                      return (
                        <Grid
                          className={classNames(classes.kioskDoor)}
                          key={n}
                          item
                          xs={3}
                          onClick={() => this.openbin(elem[n] + '' + (n1 + 1))}
                        >
                          <Paper
                            id={elem[n] + '' + (n1 + 1)}
                            className={classNames(
                              classes.paper,
                              classes.gridback
                            )}
                          >
                            {elem[n] + '' + (n1 + 1)}
                          </Paper>
                        </Grid>
                      );
                    } else {
                      return (
                        <Grid
                          className={classNames(classes.kioskDoor)}
                          key={n}
                          item
                          xs={2}
                          onClick={() => this.openbin(elem[n] + '' + (n1 + 1))}
                        >
                          <Paper
                            id={elem[n] + '' + (n1 + 1)}
                            className={classNames(
                              classes.paper,
                              classes.gridback
                            )}
                          >
                            {elem[n] + '' + (n1 + 1)}
                          </Paper>
                          {/* {this.handleActiveBin(elem[n], n1, classes)} */}
                        </Grid>
                      );
                    }
                  }
                })}
              </Grid>
            );
          })}
        </div>
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.primaryButton, classes.backBtn)}
            onClick={handleBack}
          >
            Back
          </Button>
          {/* <Button
            type="button"
            color="primary"
            variant="contained"
            // className={classNames(classes.primaryButton)}
            onClick={this.handleClickOpen}
            className={
              this.state && this.state.patientDetails.length === 0
                ? classNames(classes.disabledPrimaryButton)
                : classNames(classes.primaryButton)
            }
            disabled={this.state && this.state.patientDetails.length === 0}
          >
            Load
          </Button> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminMaintancenceFormControls)
);
