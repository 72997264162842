export const updateData = box => ({
  type: 'UPDATE_DATA',
  box
});

export const updatePollingStats = stats => ({
  type: 'UPDATE_POLLING_STATS',
  stats
});

export const startPolling = params => ({
  type: 'POLL_START',
  params
});

export const stopPolling = () => ({
  type: 'POLL_STOP'
});

export const initialData = box => ({
  type: 'INITIAL_DATA',
  box
});

export const addUrl = apidata => ({
  type: 'ADD_URL',
  apidata
});
