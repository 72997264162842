// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskLayout.styles';
import { KioskFooter, KioskBackgroundSliderContainer, KioskSlides } from '..';
import KioskTouchSound from './TouchSound';

class KioskLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    height: '530'
  };

  playTouchSound = e => {
    e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classNames(classes.container)}
        onClick={e => {
          this.playTouchSound(e);
        }}
      >
        {/* <KioskMainHeader myLogoheight={this.state.height} /> */}
        <KioskSlides />
        <KioskBackgroundSliderContainer />
        <KioskFooter customFooterBottom="0%" isLanguageBarAllowd={false} />
        <KioskTouchSound />
        {/* <KioskContainer /> */}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskLayout);
