import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './OktaLoginForm.styles';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Keyboard from 'react-simple-keyboard';

export class OktaLoginFormControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxlength: 100,
      SelectedInput: 'username',
      layoutName: 'default',
      username: '',
      password: ''
    };
  }

  static propTypes = {
    test: PropTypes.string,
    error: PropTypes.object
  };
  onChange = input => {
    this.playTouchSound();
    if (this.state.SelectedInput) {
      document.querySelector('#' + this.state.SelectedInput).value = input;
      var event = new Event('change', { bubbles: true });
      event.simulated = true;
      document
        .querySelector('#' + this.state.SelectedInput)
        .dispatchEvent(event);
      this.change(this.state.SelectedInput, event);
    }
  };
  onKeyPress = button => {
    this.playTouchSound();
    if (['{capslock}', '{shiftleft}', '{shiftright}'].includes(button))
      this.handleShiftButton();
  };
  handleShiftButton = () => {
    const {
      state: { layoutName }
    } = this;
    const shiftToggle = layoutName === 'default' ? 'shift' : 'default';
    this.setState({ layoutName: shiftToggle });
  };
  change = (name, e) => {
    this.playTouchSound();
    let input = e.target.value;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.keyboard.keyboard.setInput(input);
    });
    this.props.handleChange(e);
    this.props.setFieldTouched(name, true, false);
  };
  Focus = obj => {
    let ref = obj.target.name;
    if (this.keyboard) {
      this.setState({ SelectedInput: ref });
      this.setState({ maxlength: 100 });
      this.keyboard.keyboard.setInput(document.querySelector('#' + ref).value);
    }
  };
  Blur = () => {
    //this.setState({ SelectedInput: '' });
  };

  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };
  sendData = () => {
    this.props.handleusername(this.state.username);
  };

  render() {
    const { classes } = this.props;
    const { errors, touched, handleSubmit, handleBack, isValid } = this.props;
    this.sendData();
    return (
      <>
        <div>
          <form
            onSubmit={handleSubmit}
            className={classNames(classes.formContainer)}
          >
            <TextField
              id="username"
              name="username"
              InputProps={{
                classes: {
                  input: classes.textField
                }
              }}
              label="Email"
              autoComplete="off"
              autoCapitalize="false"
              autoFocus
              fullWidth
              // defaultValue={username}
              helperText={touched.username ? errors.username : ''}
              error={touched.username && Boolean(errors.username)}
              onChange={this.change.bind(null, 'username')}
              onFocus={this.Focus.bind('username')}
              onBlur={this.Blur.bind('username')}
              value={this.state.username}
            />
            <TextField
              id="password"
              name="password"
              InputProps={{
                classes: {
                  input: classes.textField
                }
              }}
              label="Password"
              fullWidth
              type="password"
              autoComplete="off"
              autoCapitalize="false"
              // defaultValue={password}
              helperText={touched.password ? errors.password : ''}
              error={touched.password && Boolean(errors.password)}
              onChange={this.change.bind(null, 'password')}
              onFocus={this.Focus.bind('password')}
              onBlur={this.Blur.bind('password')}
              value={this.state.password}
            />
            <div className={classNames(classes.buttonContainer)}>
              <Button
                type="button"
                color="primary"
                className={classNames(classes.backButton)}
                onClick={handleBack}
              >
                Go Back
                {/* {intl.formatMessage(messages.Back)} */}
              </Button>
              {/* <div style={{ textAlign: "center" }}> */}
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                className={
                  !isValid
                    ? classNames(classes.disabledPrimaryButton)
                    : classNames(classes.primaryButton)
                  // classNames(classes.primaryButton)
                }
                disabled={!isValid}
              >
                Sign In
                {/* {intl.formatMessage(messages.SignIn)} */}
              </Button>
              {/* </div> */}
            </div>
            <div className={classNames(classes.formLinkContainer)}></div>
          </form>
        </div>
        <div className={classNames(classes.simpleKeyboard)}>
          <Keyboard
            ref={r => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            maxLength={{ default: this.state.maxlength }}
            layout={{
              default: [
                '` 1 2 3 4 5 6 7 8 9 0 - = {backspace}',
                'q w e r t y u i o p [ ] \\',
                "{capslock} a s d f g h j k l ; '",
                'z x c v b n m , . /',
                '.com @ {space}'
              ],
              shift: [
                '~ ! @ # $ % ^ & * ( ) _ + {backspace}',
                'Q W E R T Y U I O P { } |',
                '{capslock} A S D F G H J K L : "',
                'Z X C V B N M < > ?',
                '.com @ {space}'
              ]
            }}
            onChange={input => this.onChange(input)}
            onKeyPress={button => this.onKeyPress(button)}
          />
        </div>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(OktaLoginFormControls))
);
