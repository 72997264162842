const styles = theme => {
  return {
    // container: {
    //   display: 'grid',
    //   width: '100vw',
    //   height: '100vh',
    //   gridGap: '1rem',
    //   // gridTemplateColumns: 'auto',
    //   gridTemplateRows: '50% auto 3em',
    //   backgroundImage: 'linear-gradient(135deg, white, #dedede)',
    //   justifyContent: 'center',
    //   //alignItems: 'center'
    //   alignItems: 'normal',
    //   paddingTop: '105px'
    // },
    '@media screen and (min-width: 1440px) and (orientation:landscape)': {
      container: {
        display: 'grid',
        width: '100vw',
        height: '100vh',
        gridGap: '1rem',
        gridTemplateColumns: 'auto',
        // gridTemplateRows: '12% auto 3em',
        backgroundImage: 'linear-gradient(135deg, white, #dedede)',
        alignItems: 'normal',
        paddingTop: '105px'
      }
    }
  };
};

export default styles;
