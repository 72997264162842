import { readSettings } from '../../helpers/board';
import step from '../Common/show_hide_steps';

let FileObje = {
  boxId: '',
  dynamicDataSource: false,
  rearStock: false,
  IsActive: true,
  pickupType: '1',
  steps: [
    {
      step: 0,
      name: 'Touch to Start',
      value: true,
      prev: '',
      next: '',
      btnClick: 0,
      stepTitle: 0
    },
    {
      step: 1,
      name: 'Pick up Code Enter',
      value: true,
      prev: '/',
      next: '/KioskVerify',
      btnClick: 0,
      stepTitle: 1
    },
    {
      step: 2,
      name: 'DOB verification',
      value: true,
      prev: '/KioskPickupCode',
      next: '/KioskConfirmPickup',
      btnClick: 0,
      stepTitle: 2
    },
    {
      step: 3,
      name: 'Confirm Pick up items',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 3
    },
    {
      step: 4,
      name: 'Consult',
      value: true,
      prev: '/KioskConfirmPickup',
      next: '/KioskSignature',
      btnClick: 0,
      stepTitle: 4
    },
    {
      step: 5,
      name: 'Signature',
      value: true,
      prev: '/KioskConsult',
      next: '/KioskConfirmUser',
      btnClick: 0,
      stepTitle: 5
    },
    {
      step: 6,
      name: 'DL Capture',
      value: true,
      prev: '/KioskSignature',
      next: '/KioskCollectMedicines',
      btnClick: 0,
      stepTitle: 6
    },
    {
      step: 7,
      name: 'Collect your items',
      value: true,
      prev: '',
      next: '/KioskFeedback',
      btnClick: 0,
      stepTitle: 7
    },
    {
      step: 8,
      name: 'Survey',
      value: true,
      prev: '',
      next: '/',
      btnClick: 0,
      stepTitle: 8
    },
    {
      step: 9,
      name: 'Confirm Pickup Payment',
      value: true,
      prev: '/KioskVerify',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 9
    },
    {
      step: 10,
      name: 'View Current Rx',
      value: true,
      prev: '/KioskPatientViewRx',
      next: '/KioskConsult',
      btnClick: 0,
      stepTitle: 0
    }
  ],
  posWaitDuration: 3000,
  IsNDCVideoAllow: false
};
export const skipSteps = currentStep => {
  let newSteps = null;
  if (window.electron) {
    const fileData = JSON.parse(readSettings());
    if (fileData && fileData.steps) {
      FileObje.steps = fileData.steps;
    }
  } else {
    FileObje.steps =
      localStorage.getItem('steps') !== null &&
      localStorage.getItem('steps') !== undefined
        ? JSON.parse(localStorage.getItem('steps'))
        : FileObje.steps;
  }
  FileObje.steps.filter(x => {
    if (x.step === currentStep) {
      newSteps = x;
    }
    return true;
  });
  FileObje.pickupType =
    localStorage.getItem('pickupType') &&
    localStorage.getItem('pickupType') !== null
      ? localStorage.getItem('pickupType')
      : '1';
  return newSteps;
};
export const readKioskSettings = type => {
  if (window.electron && type === 1) {
    const fileData = JSON.parse(readSettings());
    FileObje.boxId = fileData ? fileData.boxId : '';
    FileObje.IsActive = fileData ? fileData.IsActive : true;
    FileObje.dynamicDataSource = fileData ? fileData.dynamicDataSource : false;
    FileObje.rearStock = fileData ? fileData.rearStock : false;
    FileObje.IsNDCVideoAllow = fileData ? fileData.IsNDCVideoAllow : false;
    if (fileData && fileData.steps) {
      FileObje.steps = fileData.steps;
    }
  } else {
    FileObje.boxId = localStorage.getItem('boxId')
      ? localStorage.getItem('boxId')
      : '';
    FileObje.IsActive = localStorage.getItem('IsActive')
      ? localStorage.getItem('IsActive')
      : true;
    FileObje.dynamicDataSource = JSON.parse(
      localStorage.getItem('dynamicDataSource')
    );
    FileObje.rearStock =
      localStorage.getItem('rearStock') &&
      (localStorage.getItem('rearStock') === 'true' ||
        localStorage.getItem('rearStock') === true)
        ? true
        : false;
    FileObje.IsNDCVideoAllow =
      localStorage.getItem('IsNDCVideoAllow') &&
      (localStorage.getItem('IsNDCVideoAllow') === 'true' ||
        localStorage.getItem('IsNDCVideoAllow') === true)
        ? true
        : false;
    FileObje.pickupType =
      localStorage.getItem('pickupType') &&
      localStorage.getItem('pickupType') !== null
        ? localStorage.getItem('pickupType')
        : '1';
    if (
      localStorage.getItem('steps') !== null &&
      localStorage.getItem('steps') !== undefined
    ) {
      FileObje.steps = JSON.parse(localStorage.getItem('steps'));
    } else {
      const fileData = JSON.parse(readSettings());
      if (fileData && fileData.steps) {
        FileObje.steps = fileData.steps;
      }
    }
  }
  FileObje.pickupType =
    localStorage.getItem('pickupType') &&
    localStorage.getItem('pickupType') !== null
      ? localStorage.getItem('pickupType')
      : '1';
  return FileObje;
};
export const writeKioskSettings = (FileObje, currentStep, btnClick) => {
  let response = '';
  let countflage = false;

  FileObje.steps.filter(x => {
    if (btnClick === -1) {
      if (x.step === currentStep - 1) {
        if (x.value === false) {
          x.btnClick = btnClick;
          countflage = true;
        }
      }
    } else {
      if (x.step === currentStep) {
        if (x.value === false) {
          x.btnClick = btnClick;
          countflage = true;
        }
      }
    }
    return true;
  });
  if (countflage === true) {
    localStorage.setItem('steps', JSON.stringify(FileObje.steps));
  }
  response = 'success';
  return response;
};

export const setStepTitle = steps => {
  let stepTitleNo = 0;
  let stepsList = steps;
  stepsList.filter(x => {
    if (x.step !== step.step9 && x.value === true) {
      x.stepTitle = stepTitleNo;
      stepTitleNo++;
    }
    return true;
  });
  return stepsList;
};

export const setPickUpType = pickupType => {
  localStorage.setItem('pickupType', pickupType);
};
