// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SimpleStartKioskWelcomeScreenLayout.styles';
import {
  SimpleStartKioskWelcomeHeader,
  SimpleStartKioskWelcomeFooter,
  SimpleStartKioskWelcomeScreenMain,
  KioskIdelScreen
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';

export class SimpleStartKioskWelcomeScreenLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <SimpleStartKioskWelcomeHeader />
        <SimpleStartKioskWelcomeScreenMain />
        <SimpleStartKioskWelcomeFooter customFooterBottom="0%" />
        <KioskIdelScreen />
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  SimpleStartKioskWelcomeScreenLayout
);
