const { WritePickupInfo, readSettings } = require('../../helpers/board');
const Cryptr = require('cryptr');
const _cipher = process.env.REACT_APP_SESSION_CIPHER;
const _cryptr = new Cryptr(_cipher);

const UpdateStatusOfRx = payload => {
  let log = {
    message: `Kiosk Door Layout Screen : Update Status of Rx API called with payload : ${JSON.stringify(
      payload
    )}`
  };
  writePickupLogsToFile(log);
  try {
    fetch(payload.url, {
      method: payload.type,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload.body.updateStatusOfRxObj)
    })
      .then(response => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(RxObj => {
        let log = {
          message: `Kiosk Door Layout Screen : Getting response from update status of Rx : ${JSON.stringify(
            RxObj
          )}`
        };
        writePickupLogsToFile(log);
        updatePickupSession();
      });
  } catch (Exception) {
    let log = {
      message: `Kiosk Door Layout Screen : Getting error from update status of Rx : ${JSON.stringify(
        Exception
      )}`
    };
    writePickupLogsToFile(log);
  }
};

const sendMail = payload => {
  let pickupConfig = payload.body.pickupConfig;
  let tempConfigObj = { pickupConfig };
  let log = {
    message: `Kiosk Door Layout Screen : Send an email API called with payload : ${JSON.stringify(
      payload
    )}`
  };
  writePickupLogsToFile(log);
  try {
    fetch(payload.url, {
      method: payload.type,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tempConfigObj)
    })
      .then(response => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(emailObj => {
        let log = {
          message: `Kiosk Door Layout Screen : Getting response from Send an email API : ${JSON.stringify(
            emailObj
          )}`
        };
        writePickupLogsToFile(log);
      });
  } catch (Exception) {
    let log = {
      message: `Kiosk Door Layout Screen : Getting error from Send an email API : ${JSON.stringify(
        Exception
      )}`
    };
    writePickupLogsToFile(log);
  }
};

const syncPatientLifBoatWithCould = localPatientObject => {
  let base64Picture = null;
  let base64Signature = null;
  let orderIds = [];
  orderIds.push({
    id: localPatientObject.OrderID
  });

  if (
    localPatientObject.picture_url !== null &&
    localPatientObject.picture_url !== '' &&
    localPatientObject.picture_url !== undefined
  ) {
    base64Picture = localPatientObject.picture_url.replace(
      /^data:image\/\w+;base64,/,
      ''
    );
  }
  if (
    localPatientObject.signature_url !== null &&
    localPatientObject.signature_url !== '' &&
    localPatientObject.signature_url !== undefined
  ) {
    base64Signature = localPatientObject.signature_url.replace(
      /^data:image\/\w+;base64,/,
      ''
    );
  }
  let data = new FormData();
  data.append('customer_id', localPatientObject.CustomerID);
  data.append('order_id', localPatientObject.OrderID);
  data.append('picture_url', base64Picture);
  data.append('signature_url', base64Signature);
  data.append('first_name', localPatientObject.FirstName);
  data.append('middle_name', null);
  data.append('last_name', localPatientObject.LastName);
  data.append('dob', localPatientObject.dateOfBirth);
  data.append('box_id', localPatientObject.BoxID);
  data.append('account_id', localPatientObject.AccountID);
  data.append('orderIds', JSON.stringify(orderIds));
  data.append('is_consult_requested', localPatientObject.isConsultRequested);
  try {
    fetch(process.env.REACT_APP_BASEURL + 'lifeboat/save/patient/details', {
      method: 'POST',
      body: data
    })
      .then(response => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(uData => {});
  } catch (Exception) {
    console.log(
      `Error while saving DL Capture events : ${JSON.stringify(Exception)}`
    );
  }
};

const writePickupLogsToFile = log => {
  if (window && window.electron) {
    const fileData = JSON.parse(readSettings());
    let EnableLifeboat = fileData ? fileData.EnableLifeboat : false;
    if (EnableLifeboat) {
      let logObject = {
        timestamp: new Date(),
        message: log.message
      };
      WritePickupInfo(logObject);
    }
  }
};

const sendAlarmMessageForOutOfSession = () => {
  let date = new Date().toLocaleDateString();
  let time = new Date().toLocaleTimeString();
  let _doorOpenDate = `${date} ${time}`;
  const fileData = JSON.parse(readSettings());
  let boxId = fileData ? fileData.boxId : '';
  let obj = {
    boxId: boxId,
    doorOpenDate: _doorOpenDate
  };
  try {
    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    fetch(process.env.REACT_APP_BASEURL + 'session/alarm', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + actualToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then(response => {
        try {
          return response.text();
        } catch (error) {
          return null;
        }
      })
      .then(uData => {
        console.log(`Response from alarm ${JSON.stringify(uData)}`);
      });
  } catch (Exception) {
    console.log(`Error while sending alarm : ${JSON.stringify(Exception)}`);
  }
};

const getDuration = (endDate, startDate) => {
  let duration = (endDate.getTime() - startDate.getTime()) / 1000;
  duration /= 60;
  return Math.abs(Math.round(duration));
};

const updatePickupSession = () => {
  const _checkIfSessionIsActiveOrNot = localStorage.getItem('ActiveSession');
  if (_checkIfSessionIsActiveOrNot) {
    let decryptedSessionObject = _cryptr.decrypt(_checkIfSessionIsActiveOrNot);
    const parsedDecryptedObject = JSON.parse(decryptedSessionObject);
    const session_id = parsedDecryptedObject._id;
    const start_date = parsedDecryptedObject._start_date;
    const duration = getDuration(new Date(start_date), new Date());
    const obj = {
      _id: session_id,
      _session_active: false,
      _end_date: new Date(),
      _duration: duration
    };
    parsedDecryptedObject._session_active = false;
    parsedDecryptedObject._end_date = new Date();
    fetch(process.env.REACT_APP_BASEURL + 'sessionhistory', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
    })
      .then(response => {
        try {
          return response.json();
        } catch (error) {
          return null;
        }
      })
      .then(res => {
        console.log(`Session History Object ${JSON.stringify(res)}`);
      });
  }
};

module.exports = {
  UpdateStatusOfRx,
  sendMail,
  syncPatientLifBoatWithCould,
  writePickupLogsToFile,
  sendAlarmMessageForOutOfSession
};
