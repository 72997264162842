const styles = theme => {
  return {
    container: {
      display: 'grid',
      minHeight: '75vh',
      gridAutoFlow: 'dense',
      justifyContent: 'center',
      minWidth: '50vh'
    },
    appLogoContainer: {
      display: 'flex',
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
    appLogo: {
      width: '350px'
    },
    appName: {
      fontSize: '2.25em',
      fontWeight: '100',
      color: '#005d67',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      fontSize: '1.5em',
      fontWeight: '100',
      color: '#005d67'
    },
    Instructiontitle: {
      fontSize: '1.5em',
      fontWeight: '500',
      color: '#82C2AC',
      display: 'grid',
      justifyContent: 'center'
    },
    form: {
      width: '50vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '0.75em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: 'inherit',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      /*border is optional*/
      borderBottom: '1px solid #444',
      cursor: 'pointer'
    },
    '@media screen and (max-width: 1080px)': {
      form: {
        width: '40vh',
        alignItems: 'center',
        justifyContent: 'center'
      },
      container: {
        display: 'grid',
        minHeight: '56vh',
        gridAutoFlow: 'dense',
        justifyContent: 'center',
        minWidth: '50vh'
      }
    },
    '@media screen and  (min-width: 1440px) and (orientation:landscape)': {
      appLogoContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        height: '80px',
        alignItems: 'flex-end',
        justifyContent: 'center'
      },
      appLogo: {
        width: '200px'
      },
      container: {
        display: 'grid',
        minHeight: '500px',
        textAlign: 'center',
        gridAutoFlow: 'dense'
        // justifyContent:'center'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)': {
      container: {
        display: 'flex',
        minHeight: '47vh',
        flexDirection: 'column',
        minWidth: '700px',
        justifyContent: 'flex-start'
      },
      form: {
        width: '100%'
      },
      appName: {
        fontSize: '2em',
        margin: '9px'
      },
      Instructiontitle: {
        fontSize: '1.5em',
        fontWeight: '500',
        color: '#82C2AC',
        display: 'grid',
        justifyContent: 'center',
        margin: '10px',
        marginBottom: '15px'
      }
    }
  };
};

export default styles;
