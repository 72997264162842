import { connect } from 'react-redux';
import AdminPanelStockForm from './AdminPanelStockForm';

import * as AdminLoginActions from './AdminPanelStockActionCreator';
import * as localeActions from './AdminPanelStockLocalActionCreator';

const mapStateToProps = state => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk =
    // state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
    state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminLoginActions,
  ...localeActions
};

const AdminPanelStockFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPanelStockForm);

export default AdminPanelStockFormContainer;
