import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import styles from './KioskFeedbackLayout.styles';
import { connect } from 'react-redux';
// import { Paper } from '@material-ui/core';
// import EventLogHandler from '../Common/event-log-handler';
import { readSettings } from '../../helpers/board';
import { readKioskSettings } from '../Common/skipSteps';
import step from '../Common/show_hide_steps';
// import allApiCall from '../Common/allApiCall';
import {
  KioskFooter,
  KioskHeader,
  KioskFeedbackMain,
  KioskIdelScreen
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import axios from 'axios';
import { updateData, initialData } from '../../api/common/actions';
import { injectIntl } from 'react-intl';
const { writePickupLogsToFile } = require('../Common/SyncAPIs');
let FileObje = null;
export class KioskFeedbackLayout extends Component {
  constructor(props) {
    super(props);
    FileObje = readKioskSettings(2);
    if (FileObje !== null) {
      let index = FileObje.steps.findIndex(x => x.value === false);
      if (index > -1) {
        let res = FileObje.steps.filter(x => x.step === step.step8);
        res = res[0];
        if (res.value === false) {
          // allApiCall(FileObje, this.props);
          this.props.history.push(res.next);
          this.props.history.push(res.next);
        }
      }
    }
  }
  static propTypes = {
    test: PropTypes.string
  };
  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  componentDidMount() {
    let index = FileObje.steps.findIndex(
      x => x.step === step.step8 && x.value === true
    );
    if (index > -1) {
      let mode;
      if (window.electron) {
        const fileData = JSON.parse(readSettings());
        mode = fileData ? fileData.dynamicDataSource : false;
      } else {
        mode = JSON.parse(localStorage.getItem('dynamicDataSource'));
      }
      if (mode === true) {
        //   allApiCall(FileObje, this.props);
        this.getBoxesData();
      }
    }
  }
  handleClick = () => {
    this.playTouchSound();
    setTimeout(() => {
      localStorage.setItem('Paymentreceived', false);
      let log = {
        message: `Kiosk Feedback Screen : Patient navigated to Touch To Start Screen`
      };
      writePickupLogsToFile(log);
      this.props.history.push('/');
      this.props.history.push('/');
    }, 30);
  };
  getBoxesData = () => {
    let boxId;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }
    //let boxId = localStorage.getItem('boxId');

    const token = localStorage.getItem('authTokens');
    const actualToken = atob(token);
    if (boxId) {
      return axios
        .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`, {
          headers: {
            Authorization: 'Bearer ' + actualToken,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.props.updateData(response.data);
          return response.data;
        });
    } else {
      return axios
        .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${null}`, {
          headers: {
            Authorization: 'Bearer ' + actualToken,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.props.updateData(response.data);
          return response.data;
        });
    }
  };
  render() {
    const { classes, requestPayloads } = this.props;
    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <KioskHeader />
        <KioskFeedbackMain
          handleClick={this.handleClick}
          requestPayloads={requestPayloads}
        />
        <KioskFooter customFooterBottom={'0%'} isLanguageBarAllowd={true} />
        <KioskIdelScreen />
        <KioskTouchSound />
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  updateData: box => dispatch(updateData(box)),
  initialData: box => dispatch(initialData(box))
});
const mapStateToProps = state => {
  const kiosk =
    // state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
    state.get('kiosk');
  return {
    ...kiosk
  };
};

const KioskFeedbackContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KioskFeedbackLayout);

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(KioskFeedbackContainer))
);
