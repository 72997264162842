import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Formik } from 'formik';
import styles from './OktaLoginForm.styles';
import OktaLoginFormControls from './OktaLoginFormControls';
import { injectIntl, defineMessages } from 'react-intl';
import { withRouter } from 'react-router';
import { KioskFooter, KioskIdelScreen } from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import spinner from './images/spinner.gif';
import AdminHeader from '../AdminLayout/AdminHeader';
import * as Yup from 'yup';

import '@okta/okta-auth-js/polyfill';
import { OktaAuth } from '@okta/okta-auth-js';

import uuidGenerator from '../Common/uuidGenerator';
const Cryptr = require('cryptr');
const cryptr = new Cryptr('iLocalBox');
const _cryptr = new Cryptr(process.env.REACT_APP_SESSION_CIPHER);

const messages = defineMessages({
  EnterEmail: {
    id: 'Admin.Login.EnterEmail',
    defaultMessage: 'Enter your email address'
  },
  EnterValidEmail: {
    id: 'Admin.Login.EnterValidEmail',
    defaultMessage: 'Enter a valid email address'
  },
  EmailRequired: {
    id: 'Admin.Login.EmailRequired',
    defaultMessage: 'Email address is required'
  },
  EnterPassword: {
    id: 'Admin.Login.EnterPassword',
    defaultMessage: 'Enter your password'
  },
  PasswordEightChar: {
    id: 'Admin.Login.PasswordEightChar',
    defaultMessage: 'Password must contain at least 8 characters'
  },
  PasswordOneUpper: {
    id: 'Admin.Login.PasswordOneUpper',
    defaultMessage: 'Password must contain at least one uppercase character'
  },
  PasswordOneLower: {
    id: 'Admin.Login.PasswordOneLower',
    defaultMessage: 'Password must contain at least one lowercase character'
  },
  PasswordOneNumber: {
    id: 'Admin.Login.PasswordOneNumber',
    defaultMessage: 'Password must contain at least one numeric character'
  },
  PasswordRequired: {
    id: 'Admin.Login.PasswordRequired',
    defaultMessage: 'Password is required'
  }
});

let USERNAME;
export class OktaLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxlength: 100,
      isLoader: false,
      usernameError: false,
      passwordError: false,
      nameError: false,
      user: null,
      NEW_PASSWORD_REQUIRED: false,
      forgotPasswordSMSFlow: false,
      userid: ''
    };
  }

  componentDidMount() {
    this.auth();
  }

  playTouchSound = () => {
    let sound = document.getElementById('touchSound');
    sound.play();
  };
  handleback = () => {
    this.playTouchSound();
    setTimeout(() => {
      this.props.history.push('/SecureBadgeLogin');
    }, 30);
  };
  binduser = data => {
    USERNAME = data;
  };

  auth = async (username, password, issuer, clientId) => {
    const config = {
      pkce: true,
      devMode: true,
      scopes: ['openid', 'profile', 'email'],
      disableHttpsCheck: true,
      redirectUri: process.env.REACT_APP_REDIRECT_URI
    };

    issuer
      ? (config.issuer = issuer)
      : (config.issuer = sessionStorage.getItem('issuer'));
    clientId
      ? (config.clientId = clientId)
      : (config.clientId = sessionStorage.getItem('clientId'));

    if (window.location.href.match('implicit/callback')) {
      if (!config.issuer && username) {
        fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/' + username, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then(response => {
            try {
              return response.json();
            } catch (error) {
              return null;
            }
          })
          .then(uData => {
            if (uData.success) {
              config.issuer = uData.issuer || uData.parentAccount.issuer;
              config.clientId = uData.clientId || uData.parentAccount.client_id;
            }
          })
          .catch(error => {
            if (error && error.response) {
              console.error(error.response);
            }
          });
      } else if (
        !config.issuer &&
        !username &&
        window.location.href.match('iss=(.+)')
      ) {
        const issuerFromQueryString = decodeURIComponent(
          window.location.href.match('iss=(.+)')[1]
        );
        console.log(issuerFromQueryString);
        const response = await fetch(
          process.env.REACT_APP_BASEURL +
            'auth/oidc/issuer/' +
            encodeURIComponent(issuerFromQueryString),
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          }
        ).then(response => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        });
        if (response.success) {
          config.issuer = response.issuer;
          config.clientId = response.clientId;
          sessionStorage.setItem('issuer', response.issuer);
          sessionStorage.setItem('clientId', response.clientId);
        }
      }
    }

    if (!config.issuer && sessionStorage.getItem('issuer')) {
      config.issuer = sessionStorage.getItem('issuer');
      config.clientId = sessionStorage.getItem('clientId');
    }

    if (!config.issuer) {
      return;
    }

    // initiating OktaAuth instance
    const authClient = new OktaAuth(config);
    if (authClient.isLoginRedirect()) {
      // Parse token from redirect url
      const data = await authClient.token.parseFromUrl();
      const { idToken } = data.tokens;
      authClient.tokenManager.setTokens(data.tokens);
      fetch(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
        method: 'GET',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken.idToken
        }
      })
        .then(response => response.json())
        .then(Permissons => {
          if (Permissons.permission) {
            const encryptedString = cryptr.encrypt(
              JSON.stringify(Permissons.permission)
            );
            localStorage.setItem('permissions', encryptedString);
            let session = {
              _id: uuidGenerator(),
              _name: 'Kiosk Admin Login',
              _session_active: true,
              _start_date: new Date(),
              _end_date: null
            };
            const encryptedSessionObject = _cryptr.encrypt(
              JSON.stringify(session)
            );
            localStorage.setItem('ActiveSession', encryptedSessionObject);
            sessionStorage.setItem(
              'user',
              JSON.stringify({
                signInUserSession: {
                  idToken: {
                    jwtToken: idToken.idToken,
                    payload: {
                      ...idToken.claims
                    }
                  }
                },
                username: idToken.claims.email
              })
            );
          }
          if (Permissons.superAdmin) {
            localStorage.setItem('permissionUser', Permissons.superAdmin);
          }
          this.props.history.push('/AdminPanel');
        })
        .catch(err => {
          // TODO: add custom validator for exeptions!
        });
    } else {
      // Attempt to retrieve ID Token from Token Manager
      const idToken = await authClient.tokenManager.get('idToken');
      if (idToken) {
        // User already logged in
        console.log(`Hi ${idToken.claims.email}!`);
        fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/users', {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken.value
          }
        })
          .then(response => {
            try {
              return response.json();
            } catch (error) {
              return null;
            }
          })
          .then(uData => {
            if (uData.success) {
              sessionStorage.setItem(
                'user',
                JSON.stringify({
                  signInUserSession: {
                    idToken: {
                      jwtToken: idToken.value,
                      payload: {
                        ...idToken.claims
                      }
                    }
                  },
                  username: idToken.claims.email
                })
              );
              sessionStorage.setItem(
                'parentAccountName',
                uData.parentAccount.name
              );
              // this.props.history.push('/AdminPanel');
            } else {
              console.error(uData.message);
            }
          })
          .catch(error => {
            if (error && error.response) {
              console.error(error.response);
            }
          });
      } else {
        authClient
          .signInWithCredentials({ username, password })
          .then(transaction => {
            if (transaction.status === 'SUCCESS') {
              authClient.token.getWithRedirect({
                sessionToken: transaction.sessionToken,
                responseType: 'id_token'
              });
              sessionStorage.setItem(
                'jwtToken',
                JSON.stringify(transaction.sessionToken)
              );
              this.props.history.push('/AdminPanel');
            }
            if (transaction.status === 'MFA_REQUIRED' || 'MFA_ENROLL') {
              fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/createToken', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  email: transaction.user.profile.login,
                  expiry: transaction.expiresAt
                })
              })
                .then(response => {
                  try {
                    return response.json();
                  } catch (error) {
                    return null;
                  }
                })
                .then(async res => {
                  if (res.success) {
                    sessionStorage.setItem(
                      'jwtToken',
                      JSON.stringify(res.token)
                    );
                    fetch(
                      process.env.REACT_APP_BASEURL + 'getRolesPermissions',
                      {
                        method: 'GET',
                        withCredentials: true,
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: 'Bearer ' + res.token
                        }
                      }
                    )
                      .then(response => response.json())
                      .then(Permissons => {
                        if (Permissons.superAdmin) {
                          localStorage.setItem(
                            'permissionUser',
                            Permissons.superAdmin
                          );
                        }
                        if (Permissons.permission) {
                          const encryptedString = cryptr.encrypt(
                            JSON.stringify(Permissons.permission)
                          );
                          localStorage.setItem('permissions', encryptedString);
                          let session = {
                            _id: uuidGenerator(),
                            _name: 'Kiosk Admin Login',
                            _session_active: true,
                            _start_date: new Date(),
                            _end_date: null
                          };
                          const encryptedSessionObject = _cryptr.encrypt(
                            JSON.stringify(session)
                          );
                          localStorage.setItem(
                            'ActiveSession',
                            encryptedSessionObject
                          );
                          let user = {};
                          user.signInUserSession = {};
                          user.signInUserSession.idToken = {};
                          user.signInUserSession.idToken.jwtToken = res.token;
                          user.signInUserSession.idToken.payload = res.user;
                          user.username = res.user.username;
                          sessionStorage.setItem('user', JSON.stringify(user));
                          this.props.history.push('/AdminPanel');
                          // sessionStorage.setItem('user', {
                          //   signInUserSession: {
                          //     idToken: {
                          //       jwtToken: data.idToken || data.token,
                          //       payload: data.claims || data.user
                          //     }
                          //   },
                          //   username: data.claims.email || data.user.username
                          // });
                        }
                      })
                      .catch(err => {
                        // TODO: add custom validator for exeptions!
                      });
                  }
                })
                .catch(error => {
                  if (error && error.response) {
                    console.error(error.response);
                  }
                });
            }
          })
          .catch(err => console.error(err));
      }
    }
  };

  handleOktaLogin = formData => {
    if (!formData.username) {
      this.setState({ usernameError: true });
    } else {
      this.setState({ usernameError: false });
      fetch(process.env.REACT_APP_BASEURL + 'auth/oidc/' + formData.username, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          try {
            return response.json();
          } catch (error) {
            return null;
          }
        })
        .then(uData => {
          if (uData.success) {
            this.setState({ instruction: 'Welcome', usernameError: false });
            const issuer = uData.issuer || uData.parentAccount.issuer;
            const clientId = uData.clientId || uData.parentAccount.client_id;
            sessionStorage.setItem('issuer', issuer);
            sessionStorage.setItem('clientId', clientId);
            this.auth(formData.username, formData.password, issuer, clientId);
          } else {
            this.setState({
              instruction: uData.message,
              usernameError: true
            });
          }
        })
        .catch(error => {
          if (error && error.response) {
            console.log(error.response);
          }
        });
    }
  };

  render() {
    const { classes, intl } = this.props;
    const validationSchema = Yup.object({
      username: Yup.string(intl.formatMessage(messages.EnterEmail))
        .email(intl.formatMessage(messages.EnterValidEmail))
        .required(intl.formatMessage(messages.EmailRequired)),
      password: Yup.string(intl.formatMessage(messages.EnterPassword))
        .min(8, intl.formatMessage(messages.PasswordEightChar))
        .matches(/[A-Z]/, intl.formatMessage(messages.PasswordOneUpper), true)
        .matches(/[a-z]/, intl.formatMessage(messages.PasswordOneLower), true)
        .matches(/[0-9]/, intl.formatMessage(messages.PasswordOneNumber), true)
        .required(intl.formatMessage(messages.PasswordRequired))
    });
    return (
      <>
        <AdminHeader myLogoheight="300" />
        <div
          onClick={() => this.playTouchSound()}
          className={classNames(classes.mainContainer)}
        >
          <div className={classNames(classes.container)}>
            <div className={classNames(classes.appLogoContainer)}>
              <img
                className={classNames(classes.appLogo)}
                alt="app-logo"
                src="/images/brands/Steps/Welcome-Line.png"
              />
            </div>
            <div>
              <span className={classNames(classes.appName)}>Sign In</span>
              &nbsp;
              <span className={classNames(classes.title)}>with Okta</span>
            </div>
            {this.state.isLoader === true ? (
              <div className={classes.spinner}>
                <img
                  src={spinner}
                  alt="spinner"
                  className={classes.spinnerImage}
                />
              </div>
            ) : null}
            <div>
              <div className={classNames(classes.leftFormContent)}>
                <div className={classNames(classes.form)}>
                  <Formik
                    className={classNames(classes.formContainer)}
                    render={props => (
                      <OktaLoginFormControls
                        {...props}
                        handleBack={this.handleback}
                        handleusername={this.binduser}
                      />
                    )}
                    validationSchema={validationSchema}
                    initialValues={{ username: '', password: '' }}
                    onSubmit={(formData, formActions) => {
                      this.handleOktaLogin(formData);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <KioskIdelScreen />
        <KioskFooter customFooterBottom="0%" isLanguageBarAllowd={true} />
        <KioskTouchSound />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(OktaLoginForm))
);
