const styles = theme => {
  return {
    container: {
      display: 'grid',
      gridGap: '1em',
      // justifyContent:'center',
      alignItems: 'center'
    },
    primaryButton: {
      marginTop: '1em',
      background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
      fontSize: '2em'
    },
    disabledPrimaryButton: {
      marginTop: '1em',
      fontSize: '2em'
    },
    linkContainer: {
      marginTop: '0.75em',
      fontSize: '2em'
    },
    buttonLink: {
      marginTop: '5px',
      background: 'none!important',
      color: '#005d67',
      border: 'none',
      padding: '0!important',
      font: 'inherit',
      /*border is optional*/
      borderBottom: '1px solid #005d67',
      cursor: 'pointer'
    },
    codeGrid: {
      display: 'grid',
      //
      gridColumnGap: '10px'
    },
    textField: {
      fontSize: '2em'
    },
    simpleKeyboard: {
      width: '640px',
      position: 'absolute',
      left: '5%',
      bottom: '-20%',
      zIndex: 999
    },
    togglerP: {
      cursor: 'pointer',
      fontSize: '18px',
      textDecoration: 'underline'
    },
    formFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    },
    '@media screen and (max-width: 1080px)': {
      simpleKeyboard: {
        width: '50vh',
        position: 'absolute',
        left: '0%',
        bottom: '-34%',
        zIndex: 999
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)': {
      simpleKeyboard: {
        width: '100%',
        position: 'absolute',
        left: '0%',
        right: '0%',
        zIndex: 999,
        bottom: '-31vh'
      },
      primaryButton: {
        width: '40%',
        marginTop: '0em',
        background: 'linear-gradient(135deg, #50A684 30%, #115E67 90%)',
        fontSize: '1em'
      },
      disabledPrimaryButton: {
        width: '40%',
        marginTop: '0em',
        fontSize: '1em'
      },
      formFooter: {
        flexDirection: 'row',
        position: 'absolute',
        bottom: '12px',
        left: '0',
        right: '0',
        width: '700px',
        margin: '0 auto'
      }
    }
  };
};

export default styles;
