import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminKioskVerifyLayout.styles';
import {
  AdminHeader,
  AdminFooter,
  AdminSideSlides,
  AdminKioskVerifyMain,
  KioskIdelScreen
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  ScanCodeToVerify: {
    id: 'Admin.Verify.ScanCodeToVerify',
    defineMessages: 'Scan the stock code to verify.'
  }
});

export class AdminKioskVerifyLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle:
      this.props && this.props.intl.formatMessage(messages.ScanCodeToVerify),
    height: 300
  };

  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminKioskVerifyMain />
        <AdminFooter customFooterBottom="0%" />
        <KioskIdelScreen />
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminKioskVerifyLayout)
);
