import { connect } from 'react-redux';
import AdminMaintenanceForm from './AdminMaintenanceForm';

import * as AdminMaintenanceActionCreator from './AdminMaintenanceActionCreator';
import * as AdminMaintenanceLocalActionCreator from './AdminMaintenanceLocalActionCreator';

const mapStateToProps = state => {
  const auth =
    state.get('auth') && state.get('auth').toJS && state.get('auth').toJS();
  const kiosk =
    // state.get('kiosk') && state.get('kiosk').toJS && state.get('kiosk').toJS();
    state.get('kiosk');
  return {
    ...auth,
    ...kiosk
  };
};

const mapDispatchToProps = {
  ...AdminMaintenanceActionCreator,
  ...AdminMaintenanceLocalActionCreator
};

const AdminMaintenanceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMaintenanceForm);

export default AdminMaintenanceFormContainer;
