import React, { Component } from 'react';
import Sound from '../Kiosk/KioskSliderImages/touch.mp3';

export class KioskTouchSound extends Component {
  constructor() {
    super();
    this.state = {
      name: 'React'
    };
  }

  render() {
    return <audio id="touchSound" src={Sound} />;
  }
}

export default KioskTouchSound;
