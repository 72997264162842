// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from './AdminSettingForm.styles';
import AdminSettingFormControls from './AdminSettingFormControls';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import axios from 'axios';
import { initialSettings } from '../../../reducers/data';
import { updateData, initialData } from '../../../api/common/actions';
import { readSettings } from '../../../helpers/board';

export class AdminSettingForm extends Component {
  handleBack = () => {
    this.props.history.push('/RearStock/AdminPanel');
  };

  onDataSourceChange = (event, status) => {
    this.getBoxesData(status);
  };

  getBoxesData = status => {
    let boxId;
    if (window.electron) {
      const fileData = JSON.parse(readSettings());
      boxId = fileData ? fileData.boxId : '';
    } else {
      boxId = localStorage.getItem('boxId');
    }
    if (status === false || status === null) {
      this.props.initialData(initialSettings);
      return true;
    } else {
      const token = localStorage.getItem('authTokens');
      const actualToken = atob(token);
      if (boxId) {
        return axios
          .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${boxId}`, {
            headers: {
              Authorization: 'Bearer ' + actualToken,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.props.updateData(response.data);
            return response.data;
          });
      } else {
        return axios
          .get(`${process.env.REACT_APP_BASEURL}getprescriptions/${null}`, {
            headers: {
              Authorization: 'Bearer ' + actualToken,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.props.updateData(response.data);
            return response.data;
          });
      }
    }
  };

  render() {
    const { classes, patients } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.form)}>
          <AdminSettingFormControls
            patients={patients}
            handleBack={this.handleBack}
            onDataSourceChange={this.onDataSourceChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ box, pollingStats }) => ({
  box,
  ...pollingStats
});

const mapDispatchToProps = dispatch => ({
  updateData: box => dispatch(updateData(box)),
  initialData: box => dispatch(initialData(box))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles, { withTheme: true })(
    injectIntl(withRouter(AdminSettingForm))
  )
);
