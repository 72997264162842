import { Component } from 'react';

export class EventLogHandler extends Component {
  handleEventLog = event_params => {
    try {
      fetch(process.env.REACT_APP_BASEURL + 'events', {
        method: 'post',
        withCredentials: true,
        headers: {
          Authorization:
            'Bearer ' + JSON.parse(sessionStorage.getItem('jwtToken')),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(event_params)
      })
        .then(response => {
          try {
            return response.text();
          } catch (error) {
            return null;
          }
        })
        .then(evt_res => {});
    } catch (Exception) {
      console.log(
        `Events : Error while logging events with details : ${JSON.stringify(
          Exception
        )}`
      );
    }
  };
}
export default EventLogHandler;
