import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { injectIntl, defineMessages } from 'react-intl';
window.state = false;

const messages = defineMessages({
  StillThere: {
    id: 'Welcome.StillThere',
    defaultMessage: 'Are you still there'
  },
  Yes: {
    id: 'Welcome.Yes',
    defaultMessage: 'Yes'
  }
});

export class KioskIdelScreen extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.state = {
      open: false,
      time: 5
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }
  startTimer() {
    this.setState({
      time: this.state.time
    });
    this.timer = setInterval(() => {
      this.setState({
        time: this.state.time - 1
      });
      if (this.state.time === 0) {
        this.stopTimer();
        this.handleBack();
      }
    }, 1000);
  }
  stopTimer() {
    this.resetTimer();
    clearInterval(this.timer);
    this.idleTimer = null;
  }
  resetTimer() {
    this.setState({ time: 5 });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
    window.state = this.state.open;
    setTimeout(() => {
      if (window.state) {
        this.props.push('/');
        this.props.push('/');
      }
    }, 10000);
  };
  handleClose = () => {
    this.setState({ open: false });
    this.stopTimer();
    window.state = false;
  };
  handleBack = () => {
    this.props.push('/');
    this.props.push('/');
  };

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={parseInt(process.env.REACT_APP_IDEAL_DEBOUNCE, 10)}
          timeout={parseInt(process.env.REACT_APP_IDEAL_TIMEOUT, 10)}
        />
        <Dialog
          fullWidth={true}
          width={'lg'}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { border: '2px solid #0C7CBA', borderRadius: '30px' }
          }}
        >
          <div
            style={{
              //background: 'linear-gradient(155deg, #ce907b 0%,#ffcfa6 90%)'
              background: 'transperent'
            }}
          >
            <DialogContent>
              <DialogContentText
                style={{
                  color: '#77818D',
                  textAlign: 'center',
                  fontSize: '1.9em',
                  fontWeight: '500'
                }}
                id="alert-dialog-description"
              >
                {this.props &&
                  this.props.intl.formatMessage(messages.StillThere)}{' '}
                ({this.state.time}) ?
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={this.handleClose}
                style={{ color: 'black', fontSize: '1.9em' }}
              >
                {this.props && this.props.intl.formatMessage(messages.Yes)}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
  _onAction(e) {
    // console.log('user did something', e);
  }
  _onActive(e) {
    // console.log('user is active', e);
  }
  _onIdle(e) {
    if (
      this.props.allowConsultation === undefined ||
      this.props.allowConsultation === false
    ) {
      this.handleClickOpen();
      this.startTimer();
    }
  }
}

export default connect(
  null,
  { push }
)(injectIntl(KioskIdelScreen));
