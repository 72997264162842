import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PatientLoginLayout.styles';
import {
  KioskWelcomeHeader,
  KioskWelcomeFooter,
  PatientLoginMain
  //AdminSideSlides
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  UnameAndPassword: {
    id: 'Admin.Login.UnameAndPassword',
    defineMessages: 'Enter your user name and password.'
  }
});
export class PatientLoginLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle:
      this.props && this.props.intl.formatMessage(messages.UnameAndPassword),
    height: 300
  };
  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <KioskWelcomeHeader myLogoheight={this.state.height} />
        {/* <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        /> */}
        <PatientLoginMain />
        <KioskWelcomeFooter customFooterBottom="0%" />
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(PatientLoginLayout)
);
