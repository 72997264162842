import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskConsultLayout.styles';
import {
  KioskFooter,
  KioskHeader,
  KioskConsultMain,
  KioskSideSlides,
  KioskIdelScreen
} from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  HaveAnswers: {
    id: 'Consult.HaveAnswers',
    defineMessages: 'You have questions, we have answers'
  },
  Steps: {
    id: 'Verify.Steps',
    defineMessages: 'Step '
  }
});

export class KioskConsultLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle:
      this.props && this.props.intl.formatMessage(messages.Steps) + '4 :',
    pageTitle:
      this.props && this.props.intl.formatMessage(messages.HaveAnswers),
    allowConsultation: false
  };
  playTouchSound = () => {
    //e.preventDefault();
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  render() {
    const { classes, allowConsultation } = this.props;
    var customFooterBottom;
    if (window.screen.width >= 1440) {
      customFooterBottom = '-25%';
    } else {
      customFooterBottom = '0%';
    }

    const consultation = localStorage.getItem('consultation');
    return (
      <div
        onClick={() => {
          this.playTouchSound();
        }}
        className={classNames(classes.container)}
      >
        <KioskHeader />
        {/* <KioskSideSlides
          stepTitle={consultation ? null : this.state.stepTitle}
          pageTitle={this.state.pageTitle}
          customHeight={120}
        /> */}
        <KioskConsultMain />
        <KioskFooter
          customFooterBottom={customFooterBottom}
          isLanguageBarAllowd={true}
        />
        {/* {!allowConsultation ? <KioskIdelScreen /> : ''} */}
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(KioskConsultLayout)
);
