import { call, put, /* takeEvery, */ takeLatest } from 'redux-saga/effects';
import {
  // LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE
} from '../components/Login/LoginActions';
import { Auth } from 'aws-amplify';
// import * as Promise from 'bluebird';

// Promise.promisifyAll(Auth);

// https://aws-amplify.github.io/docs/js/authentication

const authenticate = (username, password) => {
  return Auth.signIn(username, password);
};

// If MFA is enabled, sign-in should be confirmed with the confirmation code
// `user` : Return object from Auth.signIn()
// `code` : Confirmation code
// `mfaType` : MFA Type e.g. SMS, TOTP.
// const mfaSignIn = (user, code, mfaType) => {
//   return Auth.confirmSignIn(user, code, mfaType);
// };

const hydrateCurrentUser = () => {
  return Auth.currentAuthenticatedUser();
};

const completeNewPasswordChallenge = (user, password) => {
  return Auth.completeNewPassword(user, password, {
    name: user.challengeParam.userAttributes.name || 'NOT PROVIDED'
  });
};

// const signOut = () => {
//   return Auth.signOut();
// };

// const globalSignOut = () => {
//   return Auth.signOut({ global: true });
// };

// const changePassword = (user, oldPassword, newPassword) => {
//   return Auth.changePassword(user, oldPassword, newPassword);
// };

const forgotPassword = username => {
  return Auth.forgotPassword(username);
};

const forgotPasswordSubmit = (username, code, new_password) => {
  return Auth.forgotPasswordSubmit(username, code, new_password);
};

// To initiate the process of verifying the attribute like 'phone_number' or 'email'
// const sendVerificationCodeForCurrentUser = attr => {
//   return Auth.verifyCurrentUserAttributes(attr);
// };

// const submitVerificationCodeForCurrentUser = (attr, code) => {
//   return Auth.verifyCurrentUserAttributeSubmit(attr, code);
// };

// const getCurrentSession = () => {
//   return Auth.currentSession();
// };

function* login(action) {
  try {
    const user = yield call(
      authenticate,
      action.payload.username,
      action.payload.password
    );
    // const data = yield call([user, 'json']);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put({ type: 'AUTH/NEW_PASSWORD_REQUIRED', payload: { user } });
    } else {
      yield put({ type: LOGIN_SUCCESS, payload: { user } });
    }
  } catch (e) {
    if (
      e.code === 'NotAuthorizedException' &&
      e.message === 'User is disabled'
    ) {
      e.code = 'NotAuthorizedExceptionDisabled';
    }
    if (e.code === 'PasswordResetRequiredException') {
      yield put({
        type: 'AUTH/PASSWORD_RESET_REQUIRED',
        payload: { error: e, username: action.payload.username }
      });
    } else {
      yield put({ type: LOGIN_FAILURE, payload: { error: e } });
    }
  }
}

function* getCurrentUser(action) {
  try {
    const user = yield call(hydrateCurrentUser);
    // const data = yield call([user, 'json']);
    yield put({ type: 'AUTH/GET_CURRENT_USER_SUCCESS', payload: { user } });
  } catch (e) {
    if (
      e.code === 'NotAuthorizedException' &&
      e.message === 'User is disabled'
    ) {
      e.code = 'NotAuthorizedExceptionDisabled';
    }
    yield put({ type: 'AUTH/GET_CURRENT_USER_FAILURE', payload: { error: e } });
  }
}

function* completeNewPassword(action) {
  try {
    const user = yield call(
      completeNewPasswordChallenge,
      action.context.user,
      action.payload.newpassword
    );
    yield put({ type: LOGIN_SUCCESS, payload: { user } });
  } catch (e) {
    yield put({
      type: 'AUTH/NEW_PASSWORD_FAILED',
      payload: { error: e, user: action.context.user }
    });
  }
}

function* getVerificationCode(action) {
  try {
    const user = yield call(forgotPassword, action.payload.username);
    yield put({ type: 'AUTH/VERIFICATION_CODE_SUCCESS', payload: { user } });
    action.context.notify('Verification code is sent to your email address.', {
      variant: 'success',
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    });
  } catch (e) {
    yield put({
      type: 'AUTH/VERIFICATION_CODE_FAILURE',
      payload: { error: e, user: action.payload.username }
    });
  }
}

function* forgotPasswordSubmitCode(action) {
  try {
    yield call(
      forgotPasswordSubmit,
      action.payload.username,
      action.payload.code,
      action.payload.newpassword
    );
    const user = yield call(
      authenticate,
      action.payload.username,
      action.payload.newpassword
    );
    yield put({ type: LOGIN_SUCCESS, payload: { user } });
  } catch (e) {
    yield put({
      type: 'AUTH/VERIFICATION_CODE_FAILURE',
      payload: { error: e, user: action.payload.username }
    });
  }
}

export const authSagas = [
  takeLatest('LOGIN_REQUEST', login),
  takeLatest('AUTH/GET_CURRENT_USER_REQUEST', getCurrentUser),
  takeLatest('AUTH/NEW_PASSWORD_SUPPLIED', completeNewPassword),
  takeLatest('AUTH/GET_VERIFICATION_CODE', getVerificationCode),
  takeLatest('AUTH/SUBMIT_VERIFICATION_CODE', forgotPasswordSubmitCode)
];
