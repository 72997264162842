const styles = theme => {
  return {
    root: {
      display: 'flex',
      gridTemplateColumns: '50% 50%',
      // backgroundColor: theme.footerBackgroundColor,
      color: theme.footerTextColor,
      fontSize: theme.footerFontSize,
      padding: '10px',
      position: 'absolute',
      width: '100%',
      bottom: '0%'
    },
    copyright: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'flex-end'
    },
    Admin: {
      justifyContent: 'left',
      alignItems: 'flex-end',
      color: '#aaa',
      display: 'grid',
      padding: '30px 0px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%',
      textAlign: 'left'
    },
    space: {
      color: '#aaa',
      display: 'grid',
      padding: '10px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%'
    },
    poweredBy: {
      color: '#aaa',
      padding: '10px',
      fontSize: '0.90rem',
      float: 'left',
      width: '33.3333%',
      textAlign: 'right'
    },
    labelClass: {
      padding: '0px 35px'
    },
    spanLink: {
      cursor: 'pointer'
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end'
    },
    '@media screen and (min-width: 380px) and (max-width: 1440px) and (orientation:landscape)': {
      root: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        color: theme.footerTextColor,
        fontSize: theme.footerFontSize,
        padding: '10px',
        position: 'absolute',
        width: '100%',
        bottom: '-55%'
      }
    },
    '@media only screen and (min-width: 380px) and (max-width: 1080px) and (orientation:portrait)': {
      root: {
        // display: 'grid',
        gridTemplateColumns: '50% 50%',
        // backgroundColor: theme.footerBackgroundColor,
        color: theme.footerTextColor,
        fontSize: theme.footerFontSize,
        padding: '10px',
        position: 'absolute',
        width: '100%',
        bottom: '28.5%'
      },
      backKiosk: {
        display: 'block'
      },
      backIRS: {
        display: 'none'
      }
    },
    '@media screen and (max-device-width: 1280px) and (max-device-height: 800px)': {
      poweredBy: {
        float: 'right',
        width: '100%'
      },
      backKiosk: {
        display: 'none'
      },
      backIRS: {
        display: 'block'
      }
    }
  };
};

export default styles;
