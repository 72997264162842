// import uuidv1 from 'uuid/v1';
import uuidv4 from 'uuid/v4';
import { DateTime } from 'luxon';

export const changeLocale = (data, formActions, context) => ({
  id: uuidv4(),
  datetime: DateTime.local(), // ISO 8601 Format
  type: 'LOCALE/CHANGED',
  context: {
    formActions,
    ...context
  }, // If anything outside of auth needs to be passed to reducer, pass it in context
  payload: {
    data
  }
});
