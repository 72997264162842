import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './SecureBadgeLoginForm.styles';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import { KioskFooter, KioskHeader, KioskIdelScreen } from '..';
import KioskTouchSound from '../Kiosk/TouchSound';
import scan from './images/QRCodeScan.gif';
import { Auth } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import uuidGenerator from '../Common/uuidGenerator';
import spinner from './images/spinner.gif';
const Crypter = require('cryptr');
const cryptoJS = require('crypto-js');

const messages = defineMessages({
  Back: {
    id: 'Welcome.Back',
    defaultMessage: 'Back'
  },
  ManualPickup: {
    id: 'Welcome.ManualPickup',
    defaultMessage: 'Username/Password'
  },
  WaitingForScanDL: {
    id: 'PickingUp.WaitingForScanDL',
    defaultMessage: 'Waiting to scan your ID.'
  },
  ScanMessage: {
    id: 'Welcome.ScanMessage',
    defaultMessage:
      'If you created a secure login badge, please scan it here to access this location. If you do not have a secure badge, please touch the button below "Username / Password" to login with your credentials.'
  },
  UserNotFoundException: {
    id: 'Login.UserNotFoundException',
    defaultMessage: 'User does not exist.'
  },
  NotAuthorizedException: {
    id: 'Login.NotAuthorizedException',
    defaultMessage: 'Invalid QR code.'
  },
  InvalidQRCode: {
    id: 'PickingUp.InvalidQRCode',
    defaultMessage: 'Invalid QR Code.'
  },
  OktaLogin: {
    id: 'Welcome.OktaLogin',
    defaultMessage: 'Sign in with Okta'
  }
});

export class SecureBadgeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid:
        this.props && this.props.intl.formatMessage(messages.InvalidQRCode),
      msgForScanDL:
        this.props && this.props.intl.formatMessage(messages.WaitingForScanDL),
      ScanMes:
        this.props && this.props.intl.formatMessage(messages.ScanMessage),
      secureBadgeEncryptedCode: '',
      showProgress: false,
      isScanningProcess: false,
      typingTimeout: 0
    };
    this.handleSecureBadgeScan = this.handleSecureBadgeScan.bind(this);
  }

  playTouchSound = () => {
    let sound = document.getElementById('touchSound');
    sound.play();
  };

  handleSecureBadgeScan = () => {
    const { secureBadgeEncryptedCode } = this.state;
    if (secureBadgeEncryptedCode) {
      try {
        const secureBadgeData = cryptoJS.AES.decrypt(
          secureBadgeEncryptedCode.trim(),
          process.env.REACT_APP_CIPHER
        )
          .toString(cryptoJS.enc.Utf8)
          .split('|');

        if (secureBadgeData[2] <= Date.now()) {
          this.setState({
            instruction:
              'Your Secure Badge is expired please get a new one !!!',
            showProgress: false
          });
          return false;
        } else {
          if (secureBadgeData && secureBadgeData.length > 1) {
            Auth.signIn(secureBadgeData[0].trim(), secureBadgeData[1].trim())
              .then(user => {
                let session = {
                  _id: uuidGenerator(),
                  _name: 'Kiosk Admin Login',
                  _session_active: true,
                  _start_date: new Date(),
                  _end_date: null
                };

                console.log(
                  `Kiosk Admin Login Screen : Session has been started with details ${JSON.stringify(
                    session
                  )}`
                );

                const crypter = new Crypter(
                  process.env.REACT_APP_SESSION_CIPHER
                );
                const cryptr = new Crypter('iLocalBox');
                const encryptedSessionObject = crypter.encrypt(
                  JSON.stringify(session)
                );
                localStorage.setItem('ActiveSession', encryptedSessionObject);
                sessionStorage.setItem(
                  'jwtToken',
                  JSON.stringify(user.signInUserSession.idToken.jwtToken)
                );
                //Get Permissions for Login user
                sessionStorage.setItem('user', JSON.stringify(user));

                fetch(process.env.REACT_APP_BASEURL + 'getRolesPermissions', {
                  method: 'GET',
                  withCredentials: true,
                  headers: {
                    Authorization:
                      'Bearer ' +
                      JSON.parse(sessionStorage.getItem('user'))
                        .signInUserSession.idToken.jwtToken,
                    'Content-Type': 'application/json'
                  }
                })
                  .then(response => response.json())
                  .then(Permissons => {
                    if (
                      Permissons.permission &&
                      Permissons.permission.length > 0
                    ) {
                      const encryptedString = cryptr.encrypt(
                        JSON.stringify(Permissons.permission)
                      );
                      localStorage.setItem('permissions', encryptedString);
                      this.setState({ showProgress: false });
                    } else {
                      localStorage.setItem('permissions', '');
                    }
                    if (Permissons.superAdmin) {
                      localStorage.setItem(
                        'permissionUser',
                        Permissons.superAdmin
                      );
                    }
                    this.props.history.push('/AdminPanel');
                    this.props.history.push('/AdminPanel');
                  });
              })
              .catch(err => {
                switch (err.code) {
                  case 'UserNotFoundException':
                    this.setState({
                      instruction: err.message,
                      showProgress: false
                    });
                    break;
                  case 'NotAuthorizedException':
                  default:
                    this.setState({
                      instruction: this.state.invalid,
                      showProgress: false
                    });
                }
              });
          } else {
            this.setState({
              isScanningProcess: true,
              showProgress: false,
              secureBadgeEncryptedCode: '',
              instruction: this.state.invalid
            });
          }
        }
      } catch (error) {
        this.setState({
          isScanningProcess: true,
          showProgress: false,
          secureBadgeEncryptedCode: '',
          instruction: this.state.invalid
        });
      }
    }
  };

  render() {
    const { classes, intl } = this.props;
    const {
      showProgress,
      ScanMes,
      msgForScanDL,
      invalid,
      isScanningProcess,
      typingTimeout,
      secureBadgeEncryptedCode
    } = this.state;
    var customFooterBottom;
    if (window.screen.width === 1080) {
      customFooterBottom = '0%';
    } else if (window.screen.width === 1440 || window.screen.width === 1920) {
      customFooterBottom = '0%';
    } else {
      customFooterBottom = '0%';
    }
    return (
      <div
        onClick={() => this.playTouchSound()}
        className={classNames(classes.mainContainer)}
      >
        <div className={classNames(classes.appLogoContainer)}>
          <img
            className={classNames(classes.appLogo)}
            alt="app-logo"
            src="/images/brands/Steps/Welcome-Line.png"
          />
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '200px' }}
        >
          <span style={{ color: ' #2F5597', fontSize: '3em' }}>
            <FormattedMessage id="Login.SignIn" defaultMessage="Sign In" />
          </span>
          <span style={{ color: ' #2F5597', fontSize: '1.5em' }}>
            <FormattedMessage
              id="Welcome.SignInInstruction"
              defaultMessage="With Secure Badge"
            />
          </span>
        </div>
        {showProgress && (
          <div className={classes.spinner}>
            <img src={spinner} alt="spinner" className={classes.spinnerImage} />
          </div>
        )}
        <div>
          <img
            src={scan}
            alt="scan"
            style={{ width: '200px', marginTop: '-4.5em' }}
          />
          <TextField
            fullWidth
            autoFocus
            value={secureBadgeEncryptedCode}
            onChange={({ target }) => {
              typingTimeout && clearTimeout(typingTimeout);
              this.setState({
                secureBadgeEncryptedCode: target.value,
                showProgress: true,
                typingTimeout: setTimeout(() => {
                  this.handleSecureBadgeScan();
                }, 2000)
              });
            }}
            onBlur={({ target }) => target.focus()}
            style={{
              width: '0',
              height: '0',
              position: 'absolute',
              zIndex: 1
            }}
            InputProps={{
              disableUnderline: true,
              style: { color: '#fff' },
              spellCheck: 'false',
              autoComplete: 'off'
            }}
          />
        </div>
        <div className={classNames(classes.ScantitleMessages)}>
          <label id="scanTitle" className={classNames(classes.Scantitle)}>
            {/* Waiting for scan QR ... */}
            {isScanningProcess ? invalid : msgForScanDL}
          </label>
        </div>

        <div>
          <p
            style={{
              textAlign: 'justify',
              marginLeft: '112px',
              marginRight: '112px',
              marginTop: '40px',
              color: 'rgb(47, 85, 151)'
            }}
          >
            {ScanMes}
          </p>
        </div>
        <div className={classes.oktaLoginContainer}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.oktaLoginButton)}
            onClick={() => this.props.history.push('/implicit')}
          >
            <span className={classes.oktaCircle}></span>
            {intl.formatMessage(messages.OktaLogin)}
          </Button>
        </div>
        <div className={classNames(classes.buttonContainer)}>
          <Button
            type="button"
            color="primary"
            className={classNames(classes.backButton)}
            onClick={() => this.props.history.push('/')}
          >
            {intl.formatMessage(messages.Back)}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classNames(classes.backButton)}
            onClick={() => this.props.history.push('/AdminLogin')}
          >
            {intl.formatMessage(messages.ManualPickup)}
          </Button>
        </div>
        <KioskHeader />
        <KioskFooter
          customFooterBottom={customFooterBottom}
          isLanguageBarAllowd={true}
        />
        {showProgress || <KioskIdelScreen />}
        <KioskTouchSound />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(SecureBadgeLogin))
);
